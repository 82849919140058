import styled from 'styled-components';
import {BsFillQuestionCircleFill} from 'react-icons/bs';
import {Colors} from "../../utils/Colors";
import { Link } from 'react-router-dom';

export const ModalContainer = styled.div<{ isOpen?: boolean; position?: any }>`
  display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1000;
  //width: 20%;
  height: auto;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  max-height: 100vh;
  overflow-y: scroll;

  /* Add your desired modal styles here */
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  /* Add your desired modal content styles here */
`;

export const CloseButton = styled.button`
  width: 28px;
  height: 28px;
  background-color: #ebebeb;
  color: black;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  overflow-y: auto;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #35363a;
  margin-top: 40px;
`;

// @ts-ignore
export const Item = styled(Link)`
  width: 85%;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(2, 0, 88, 0.06);
  color: #35363a;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`;

// @ts-ignore
export const StyledHelpIcon = styled(BsFillQuestionCircleFill)`
  color: ${Colors.forestGreen}; /* Specify your custom color */
  font-size: 24px; /* Customize the font size if needed */
`;

export const ItemContent = styled.div`
  color: #ff0000; /* Specify your custom color */
  font-size: 24px; /* Customize the font size if needed */
`;

export const ItemTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #35363a;
`;

export const ItemDescription = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  margin: 5px 0 20px 0;
  opacity: 0.5;
`;
