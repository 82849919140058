import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { FiSearch } from 'react-icons/fi';

const Container = styled.div`
  width: 85%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #ececec;
  border-radius: 7.46667px;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 14px;
  width: fit-content;
  color: #35363a;
`;

// @ts-ignore
const Icon = styled(FiSearch)`
  margin: 0 8px;
  color: #888;
`;

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ value, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Container>
      <Icon />
      <Input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder="Search for sessions/questions and more..."
      />
    </Container>
  );
};

export default SearchInput;
