import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Modal from 'components/Modal';
import { getQuestion } from '../../redux/slices/showQuestionSlice';
import { editVal } from '../../redux/slices/EditQuestionSlice';
import { RootState } from '../../redux/store';
import { Block, ErrorMessage } from '../styled';
import InputWithLabel from '../InputWithLabel';
import Loading from '../Loading';
import {
  PreviewBlockLayout,
  PreviewclozeDragDrop,
  PreviewclozeDragDown,
  PreviewclozeWithText,
  PreviewMultipleStandard,
  PreviewMatrixLabel,
  PreviewMatrixInline,
  PreviewMatrixStandard,
  PreviewTrueFalse,
  PreviewMultipleResponses,
  PreviewClozeMath,
  PreviewClozeImageMath,
  PreviewImageDragDrop,
  PreviewImageWithText,
  PreviewEasyImageMath,
  PreviewLabelmageMath,
  PreviewMath
} from '../../pages/Assessments/PreviewComponents';
import PreviewMatchList from 'components/PreviewQuestions/PreviewMatchList/PreviewMatchList';
import PreviewClassification from 'components/PreviewQuestions/PreviewClassification/PreviewClassification';

import PreviewSortList from 'components/PreviewQuestions/PreviewSortList/PreviewSortList';
import PreviewOrderList from 'components/PreviewQuestions/PreviewOrderList/PreviewOrderList';
import {useDispatch, useSelector} from "react-redux";

const EditBasicDetails: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { dropSubjects } = useSelector((state: RootState) => state.subjects);
  const { dropGrades } = useSelector((state: RootState) => state.grades);

  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subQuestionId, setSubQuestionId] = useState();
  const [loading, setLoading] = useState(true);
  const { name, grade, subject } = useSelector(
      (store: RootState) => store?.showQuestion?.currentQuestion ?? {}
  );

  const { currentQuestion } = useSelector(
    (state: RootState) => state.showQuestion
  );

  const editQuestion = useSelector((state: RootState) => state.editQuestion)

  console.log("current question =", currentQuestion)
  console.log("current edit question =", editQuestion)

  useEffect(() => {
    dispatch(getQuestion(id) as any);
  }, [id]);

  const {
    register,
    formState: { errors },
  } = useForm();

  // Fetch Grades and Subjects (DRY Principle)
  useEffect(() => {
    // Simulate loading for 3 seconds

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        // Render loading indicator or message
        <div style={{ marginTop: '-150px' }}>
          <Loading />
        </div>
      ) : (
        // Render content here once loading is done
        <>
          <h2 className="font-bold mb-4">Question: {name || ''}</h2>
          <div className="flex gap-5 mt-5 pb-4">
            <Block>
              <InputWithLabel
                {...register('name', { required: true })}
                label={'Question Name*'}
                placeholder={'Enter Question Name*'}
                error={errors?.name}
                onChange={(e) =>
                  dispatch(
                    editVal({
                      key: 'name',
                      val: e.target.value,
                    })
                  )
                }
                value={editQuestion?.name || ''}
                // defaultValue={name || ""} 
                width="350px"
              />
            </Block>

            <Block>
              <div className="align-center justify-center mt-1">
                <p className="font-semibold">Subjects*</p>
                <select
                  onChange={(e) =>{
                    const selectedSubject = dropSubjects?.find(s => s.id == e.target.value);
                    if(selectedSubject) {
                      dispatch(
                        editVal({
                          key: 'subject',
                          val: {...selectedSubject},
                        })
                      );
                      dispatch(
                        editVal({
                          key: 'grade',
                          val: null,
                        })
                      )
                    }else{
                      console.log("Selected Subject Doesn't exist in drop Subjects array ", dropSubjects, "selected value =", e.target.value)
                    }
                    
                  }}
                  // value={subject && subject[0]}
                  value={editQuestion?.subject?.id || subject}
                  style={{
                    border: '1px solid #ddd',
                    /*borderRadius: '6px',
                    padding: '8px',*/
                  }}
                  className="h-10 mt-1 rounded-md p-2 border border-solid "
                  required={false}
                  defaultValue={subject}
                >
                  <option disabled>Select Subject</option>
                  <option value={subject?.id}>{subject?.name}</option>
                  {dropSubjects?.map((subject) => {
                    return (
                      <option
                        key={subject?.value}
                        value={subject?.value}
                        data-label={subject?.label}
                      >
                        {subject?.label}
                      </option>
                    );
                  })}
                </select>
                {error && <ErrorMessage>Subject is required.</ErrorMessage>}
              </div>
            </Block>
            
            <Block>
              <div className="align-center justify-center mt-1">
                <p className="font-semibold">Grades</p>
                <select
                  onChange={(e) =>{
                    const selectedGrade = Array.isArray(editQuestion?.subject?.grades) ? editQuestion?.subject?.grades.find(g => g.id == e.target.value) : null;
                    if(selectedGrade) {
                      dispatch(
                        editVal({
                          key: 'grade',
                          val: {...selectedGrade,},
                        })
                      )
                    } 
                  }}
                  value = {editQuestion?.grade ? editQuestion?.grade?.id : ""}
                  style={{
                    border: '1px solid #ddd',
                    /*borderRadius: '6px',
                    padding: '8px',*/
                  }}
                  className="h-10 mt-1 rounded-md p-2 border border-solid "
                  // defaultValue={grade}
                >
                  {
                    editQuestion?.grade && editQuestion?.grade?.id ?
                      <option
                      value={editQuestion?.grade?.id}
                      // disabled={!grade?.name ? true : false}
                      >
                        {editQuestion?.grade?.name ? editQuestion?.grade?.name : editQuestion?.grade?.label ? editQuestion?.grade?.label : "-"}
                      </option>
                    :
                      <option value={""}>
                        {"Select Grade"}
                      </option>
                  }
                  {/* {subject && Array.isArray(subject?.grades) &&  subject?.grades?.map((grade) => { */}
                  { editQuestion && Array.isArray(editQuestion?.subject?.grades) &&  editQuestion?.subject?.grades?.map((grade) => {
                    return (
                      <option
                        key={grade?.value ? grade?.value : grade?.id}
                        value={grade?.value ? grade?.value : grade?.id}
                        data-label={grade?.label ? grade?.label : grade?.name}
                      >
                        {grade?.label ? grade?.label : grade?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Block>

            
          </div>

          {currentQuestion?.sub_questions?.map((question) => {
            if (['mcs'].includes(question?.type)) {
              return (
                <PreviewMultipleStandard
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['mcmr'].includes(question?.type)) {
              return (
                <PreviewMultipleResponses
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['taf'].includes(question?.type)) {
              return (
                <PreviewTrueFalse
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['mcbl'].includes(question?.type)) {
              return (
                <PreviewBlockLayout
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['cms'].includes(question?.type)) {
              return (
                <PreviewMatrixStandard
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['cmi'].includes(question?.type)) {
              return (
                <PreviewMatrixInline
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['cml'].includes(question?.type)) {
              return (
                <PreviewMatrixLabel
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['cwdad'].includes(question?.type)) {
              return (
                <PreviewclozeDragDrop
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['cwdd'].includes(question?.type)) {
              return (
                <PreviewclozeDragDown
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['cwt'].includes(question?.type)) {
              return (
                <PreviewclozeWithText
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['math'].includes(question?.type)) {
              return (
                <PreviewMath
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['clozmat'].includes(question?.type)) {
              return (
                <PreviewClozeMath
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['clozmwIm'].includes(question?.type)) {
              return (
                <PreviewClozeImageMath
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['liwdd'].includes(question?.type)) {
              return (
                <PreviewImageDragDrop
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['liwt'].includes(question?.type)) {
              return (
                <PreviewImageWithText
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['machli'].includes(question?.type)) {
              return (
                <PreviewMatchList
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['class'].includes(question?.type)) {
              return (
                <PreviewClassification
                  key={question?.id}
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['mewrt'].includes(question?.type)) {
              return (
                <PreviewEasyImageMath
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['liwm'].includes(question?.type)) {
              return (
                <PreviewLabelmageMath
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['ordlis'].includes(question?.type)) {
              return (
                <PreviewOrderList
                  currentQuestion={question}
                  // showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else if (['sortlis'].includes(question?.type)) {
              return (
                <PreviewSortList
                  currentQuestion={question}
                  showAnswer={false}
                  editMode={true}
                  parentMode={'question'}
                />
              );
            } else {
              return <div>{`hello ${question.type} question`}</div>;
            }
          })}
          {isModalOpen && (
            <Modal
              setIsModalOpen={(v) => setIsModalOpen(v)}
              id={subQuestionId}
              isModalOpen={isModalOpen}
              api="api/subQuestion"
              type="Sub Question"
              method={() => {
                dispatch(getQuestion(id) as any);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditBasicDetails;
