import React, {useRef, useState} from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {
    CloseButton,
    Content,
    Item,
    ModalContainer,
    ModalContent,
    Overlay,
    Title,
    StyledHelpIcon,
    ItemContent,
    ItemTitle,
    ItemDescription,
} from './styled';
import SearchInput from '../SearchInput';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    position: { top: number; left: number };
}

const HelpModal: React.FC<ModalProps> = ({isOpen, onClose, position}) => {
    const modalRef = useRef(null);
    useOnClickOutside(modalRef, onClose, isOpen);
    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = (value: string) => {
        setSearchValue(value);
    };

    return (
        <Overlay isOpen={isOpen}>
            <ModalContainer ref={modalRef} isOpen={isOpen} position={position}>
                <ModalContent>
                    <CloseButton onClick={onClose}>X</CloseButton>
                    <Content>
                        <Title>How can we help?</Title>
                        <SearchInput value={searchValue} onChange={handleSearchChange}/>
                        <Item to={'/add-question-bank'}>
                            <StyledHelpIcon/>
                            <ItemContent>
                                <ItemTitle>Creating Questions</ItemTitle>
                                <ItemDescription>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.{' '}
                                </ItemDescription>
                            </ItemContent>
                        </Item>
                        <Item to={'/questions-listing'}>
                            <StyledHelpIcon/>
                            <ItemContent>
                                <ItemTitle>Question Types</ItemTitle>
                                <ItemDescription>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.{' '}
                                </ItemDescription>
                            </ItemContent>
                        </Item>
                        <Item to={''}>
                            <StyledHelpIcon/>
                            <ItemContent>
                                <ItemTitle>Math Scoring</ItemTitle>
                                <ItemDescription>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.{' '}
                                </ItemDescription>
                            </ItemContent>
                        </Item>
                        <Item to={'/new-assessment'}>
                            <StyledHelpIcon/>
                            <ItemContent>
                                <ItemTitle>Creating Assessments</ItemTitle>
                                <ItemDescription>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.{' '}
                                </ItemDescription>
                            </ItemContent>
                        </Item>
                        <Item to={'/tags-types'}>
                            <StyledHelpIcon/>
                            <ItemContent>
                                <ItemTitle>Creating Tags</ItemTitle>
                                <ItemDescription>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.{' '}
                                </ItemDescription>
                            </ItemContent>
                        </Item>
                        <Item to={'/create-user'}>
                            <StyledHelpIcon/>
                            <ItemContent>
                                <ItemTitle>Creating Users</ItemTitle>
                                <ItemDescription>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.{' '}
                                </ItemDescription>
                            </ItemContent>
                        </Item>
                    </Content>
                </ModalContent>
            </ModalContainer>
        </Overlay>
    );
};

export default HelpModal;
