import React from "react";
import './styles.css';

const Loading: React.FC = () => {
    return (
        <>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
};

export default Loading;
