import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from 'types';

type DropZoneGroupProps = {
  id: number;
  onDrop?: (id: number, word: string, droppedItems: any) => void;
  droppedItems?: any;
  forReset?: any;
};

type dragItem = {
  text: string;
  droppedItems?: any;
};

const DropZoneGroup = ({
  id,
  onDrop,
  droppedItems,
  forReset,
}: DropZoneGroupProps) => {
  const elRef = useRef<Array<HTMLDivElement | null>>([]);

  const [droppedEls, setDroppedEls] = useState(
    droppedItems ? droppedItems : []
  );

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.MATCH,
    drop: (item: dragItem) => {
      setDroppedEls((prevItems) => [...prevItems, item?.droppedItems?.current]);
      onDrop(id, item?.text, item?.droppedItems);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  useEffect(() => {
    droppedEls?.map((ele, key) => {
      // parse html string
      if (elRef.current[key]) {
        elRef.current[key].innerHTML = DOMPurify.sanitize(ele);
      }
      return elRef.current[key].innerHTML;
    });
  }, [droppedEls, droppedItems]);

  useEffect(() => {
    !forReset && setDroppedEls([]);
  }, [forReset]);

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        background: '#F0F8ED',
      }}
      className="flex flex-wrap gap-1 p-2 border-solid border border-grey-500 rounded-md w-[100%] min-h-[48px]"
    >
      {droppedEls?.map((ele, key) => {
        return <div key={key} ref={(el) => (elRef.current[key] = el)} />;
      })}

      {isOver && (
        <div
          className="border-solid border border-grey-500 rounded-md"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'rgb(255, 102, 1)',
          }}
        />
      )}
    </div>
  );
};

export default DropZoneGroup;
