import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DraggableElement from '../partials/DraggableElement/DraggableElement';
import { TOptionItem } from 'types';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import DropZoneGroup from '../partials/DropZoneGroup/DropZoneGroup';
import Button from 'components/Button';

type ClassificationBlocPrevProps = {
  columnTitles: TOptionItem[];
  rowsTitles: TOptionItem[];
  possibRes: TOptionItem[];
  correctAnswers?: string[][];
  droppedItems?: string[];
  handleDrop: (index: number, word: string, droppedItems: any) => void;
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  showAnswer?: boolean;
  score?: number;
  handleDataRest?: any;
  forReset?: any;
};

export const ClassificationBlocPrev = ({
  columnTitles,
  rowsTitles,
  possibRes,
  correctAnswers,
  droppedItems,
  handleDrop,
  handlePointsChange,
  editMode,
  showAnswer,
  score,
  handleDataRest,
  forReset,
}: ClassificationBlocPrevProps) => {
  const { t } = useTranslation();
  let i = 0;

  const colRef = useRef<Array<HTMLSpanElement | null>>([]);
  const rowRef = useRef<Array<HTMLSpanElement | null>>([]);

  useEffect(() => {
    colRef.current = colRef.current.slice(0, columnTitles?.length);
  }, [columnTitles]);

  useEffect(() => {
    rowRef.current = rowRef.current.slice(0, rowsTitles?.length);
  }, [rowsTitles]);

  useEffect(() => {
    columnTitles?.map((item, i) => {
      // parse html string
      if (colRef.current[i]) {
        colRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
      return colRef.current[i].innerHTML;
    });
  }, [columnTitles]);

  useEffect(() => {
    rowsTitles?.map((item, i) => {
      // parse html string
      if (rowRef.current[i]) {
        rowRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
      return rowRef.current[i].innerHTML;
    });
  }, [rowsTitles]);

  return (
    <DndProvider backend={HTML5Backend}>
      {handleDataRest && <Button title={t('reset')} onClick={handleDataRest} />}

      <div className="mt-4">
        <div
          className={'w-full flex items-center justify-center'}
          style={{
            border: '1px solid #ccc',
            borderRadius: '6px',
            width: '100%',
          }}
        >
          <table>
            <tbody>
              <tr>
                <th>&nbsp;</th>
                {columnTitles?.map((item, key) => {
                  return (
                    <th
                      key={key}
                      className="p-2 border-solid border border-grey-500 my-4 rounded-md min-w-[20vw] min-h-[48px] "
                    >
                      <span ref={(el) => (colRef.current[key] = el)} />
                    </th>
                  );
                })}
              </tr>

              {rowsTitles?.map((item, key) => {
                return (
                  <tr key={key}>
                    <td className="p-2 border-solid border border-grey-500 my-4 rounded-md min-h-[48px] ">
                      <span ref={(el) => (rowRef.current[key] = el)} />
                    </td>

                    {columnTitles?.map((item, key) => {
                      i++;

                      return (
                        <th
                          key={key}
                          className="p-2 border-solid border border-grey-500 my-4 rounded-md min-h-[48px] "
                        >
                          <DropZoneGroup
                            id={i}
                            onDrop={handleDrop}
                            droppedItems={droppedItems}
                            forReset={forReset}
                          />

                          {showAnswer &&
                            correctAnswers &&
                            correctAnswers[i - 1]?.map(
                              (item: string, key: number) =>
                                droppedItems[i - 1]?.length > 0 ? (
                                  <div
                                    key={key}
                                    className={`border-2 p-1 my-1  ${
                                      droppedItems &&
                                      droppedItems[i - 1][key] === item
                                        ? 'border-green-500'
                                        : 'border-red-500'
                                    }`}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  ></div>
                                ) : (
                                  <div
                                    className={
                                      'border-2 p-1 my-1 border-grey-500'
                                    }
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  ></div>
                                )
                            )}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-between mt-4 gap-2">
          {/* Choices list */}

          <div className="flex items-center gap-1 flex-wrap">
            {possibRes?.map((item, key) => (
              <DraggableElement key={key} label={item.label} />
            ))}
          </div>

          {!editMode && (
            <div
              className="flex items-center gap-10 p-2"
              style={{
                border: '1px solid #eee',
                borderRadius: '6px',
                width: '300px',
              }}
            >
              <span>{t('points')}</span>
              {score ? (
                <input
                  className="outline-none"
                  type="number"
                  value={score}
                  readOnly
                />
              ) : (
                <input
                  className="outline-none"
                  placeholder="100"
                  type="number"
                  onChange={handlePointsChange}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </DndProvider>
  );
};

ClassificationBlocPrev.defaultProps = {
  editMode: false,
};
