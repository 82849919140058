import { useEffect, useRef } from 'react';

const useOnClickOutside = (ref, handler, isOpen) => {
    const savedHandler = useRef(handler);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && ref.current && !ref.current.contains(event.target)) {
                savedHandler.current(event);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, isOpen]);
};

export default useOnClickOutside;