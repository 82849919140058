import styled from 'styled-components';

interface InputProps {
  ref?: any;
  width?: any;
  height?: any;
}

export const Container = styled.div<{ direction?: any }>`
  margin: 5px;
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  //align-items: center;
`;

export const Label = styled.label`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: 'Inter';
  margin-bottom: 0.5rem;
`;

export const Input = styled.input<InputProps>`
  display: block;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '40px')};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ececec;
  border-radius: 7px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0 5px;
  margin-top: 0.5rem;
`;

export const SelectInput = styled.select<InputProps>`
  display: block;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '40px')};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ececec;
  border-radius: 7px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0 5px;
`;

export const ErrorMessage = styled.p`
  font-size: 10px;
  color: #d80a0c;
`;

export const Note = styled.p`
  font-size: 10px;
  color: #aab3c3;
`;
