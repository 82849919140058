import uuid from 'react-uuid';
import { TOptionItem, TSortDraggedElement } from 'types';

const sortDraggedElement = ({
  data,
  dragItem,
  dragOverItem,
}: TSortDraggedElement) => {
  //duplicate items
  let _optionItems = [...data];

  //remove and save the dragged item content
  const draggedItemContent = _optionItems.splice(dragItem.current, 1)[0];

  //switch the position
  _optionItems.splice(dragOverItem.current, 0, draggedItemContent);

  //reset the position ref
  dragItem.current = null;
  dragOverItem.current = null;

  return _optionItems;
};

//handle new item addition
const addNewEl = (data) => {
  const optionItems = [...data];
  // let id = optionItems.length === 0 ? 0 : _optionItems.at(-1).id + 1;
  let id = uuid();

  optionItems.push({
    id: id,
    label: '',
  });

  return optionItems;
};

//handle new Group addition
const addNewGroup = (data) => {
  const optionItems = [...data];
  let id = uuid();

  optionItems.push({
    id: id,
    title: ' ',
    responses: [],
  });

  return optionItems;
};

const renameElement = (index: number, label: string, data: TOptionItem[]) => {
  const updatedItems = [...data];

  updatedItems[index] = {
    id: updatedItems[index].id,
    label: label,
  };

  return updatedItems;
};

const setEmptyResponses = (arr: any[]) => {
  let responses = [];
  arr?.map(() => responses.push(''));
  return responses;
};

const setEmptyArrResponses = (arr: number) => {
  let responses = [];

  for (let index = 0; index < arr; index++) {
    responses.push([]);
  }
  return responses;
};

const formatDndData = (arr: any[]) => {
  let responses = [...arr];
  let temp = [];

  responses?.map((item, index) => {
    if (item.length > 0) {
      item.map((el) => {
        return temp.push(el.label);
      });

      responses[index] = temp;
      temp = [];
    } else if (item.length === 0) {
      responses[index] = [];
    }
    return temp;
  });

  return responses;
};

export {
  sortDraggedElement,
  addNewEl,
  renameElement,
  setEmptyResponses,
  setEmptyArrResponses,
  formatDndData,
  addNewGroup,
};
