import React, { useEffect, useState } from 'react';

import { setClosePreview } from '../../../redux/slices/PreviewSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';

import PreviewWrapper from '../PreviewWrapper/PreviewWrapper';
import { useNavigate } from 'react-router';
import { formatDataWithLabel } from 'utils';
import { setEmptyArrResponses } from 'pages/AuthorQuestion/ClassifyMatch/utils';
import { ClassificationBlocPrev } from 'components/subquestions/ClassificationBloc/ClassificationBlocPrev';

const PreviewClassification = ({
  currentQuestion,
  showAnswer,
  setShowAnswers,
  parentMode,
  editMode,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [subQuestion, setSubQuestion] = useState(null);
  const [correctChoices, setCorrectChoices] = useState([]);
  const [columnTitles, setColumnTitles] = useState(null);
  const [rowsTitles, setRowsTitles] = useState(null);
  const [possibleChoices, setPossibleChoices] = useState([]);

  const [correctAnswers, setCorrectAnswers] = useState(null);
  const [forReset, setForReset] = useState(null);

  /* 
    Set data with the correct responses according to the current tab type (valid or alternate)
  */
  const handleDrop = (index: number, word: string) => {
    let resposesList = [...correctChoices];

    resposesList[index - 1] = [...resposesList[index - 1], word]; // index starts from 1

    setCorrectChoices((prev) => (prev = resposesList));
    setForReset((prev) => (prev = resposesList));
  };

  useEffect(() => {
    setSubQuestion((prev) => (prev = currentQuestion?.correct_answer));

    setColumnTitles(
      (prev) =>
        (prev = formatDataWithLabel(
          currentQuestion?.correct_answer.ui_style?.column_titles
        ))
    );

    setRowsTitles(
      (prev) =>
        (prev = formatDataWithLabel(
          currentQuestion?.correct_answer.ui_style?.row_titles
        ))
    );

    setPossibleChoices(
      (prev) =>
        (prev = formatDataWithLabel(
          currentQuestion?.correct_answer?.possible_responses
        ))
    );

    setCorrectAnswers(
      (prev) => (prev = currentQuestion?.correct_answer?.valid_response?.value)
    );
  }, [currentQuestion]);

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  // Prepare the correct choices list with empty arrays according to the number of (col * rows)
  useEffect(() => {
    const respNum = columnTitles?.length * rowsTitles?.length;

    setCorrectChoices(setEmptyArrResponses(respNum));
  }, [columnTitles?.length, rowsTitles?.length]);

  const handleDataRest = () => {
    const respNum = columnTitles?.length * rowsTitles?.length;

    setCorrectChoices(setEmptyArrResponses(respNum));
    setForReset(null);
  };
  

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer}
      setShowAnswers={setShowAnswers}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
     
    >
      {subQuestion && (
        <ClassificationBlocPrev
          columnTitles={columnTitles}
          rowsTitles={rowsTitles}
          possibRes={possibleChoices}
          correctAnswers={correctAnswers}
          droppedItems={correctChoices}
          handleDrop={handleDrop}
          showAnswer={showAnswer}
          score={currentQuestion?.correct_answer?.valid_response?.score}
          handleDataRest={handleDataRest}
          forReset={forReset}
        />
      )}
    </PreviewWrapper>
  );
};

export default PreviewClassification;

// default props
PreviewClassification.defaultProps = {
  showAnswer: false,
  setShowAnswers: null,
  parentMode: 'assessment',
  editMode: false,
};
