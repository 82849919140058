import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = `${process.env.REACT_APP_API_URL}/api/tagType`;

interface TAgTypes {
  isLoading: boolean;
  allTagTypes: any[];
  pagination: {};
  subjects: object[];
  grades: object[];
}

const initialState: TAgTypes = {
  allTagTypes: [],
  isLoading: true,
  pagination: null,
  subjects: [],
  grades: [],
};

// Get all tag types
export const getAllTagTypes = createAsyncThunk(
    'author/getAllTagTypes',
    async (page) => {
      try {
        const resp = await axios(
            `${process.env.REACT_APP_API_URL}/api/tagType/datatable`,
            {
              params: {
                page: page[0],
                per_page: page[1],
                ...page[2], // Include additional filter parameters here
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                Accept: 'application/json',
              },
            }
        );
        console.log('datatype tag => ', resp.data);

        return resp.data;
      } catch (error) {
        console.log('Err ', error);

        // return thunkAPI.rejectWithValue('something went wrong');
      }
    }
);

const TagTypesSlice = createSlice({
  name: 'TAgTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getAllTagTypes.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getAllTagTypes.fulfilled, (state, action) => {
          state.isLoading = false;
          state.allTagTypes = action.payload?.items;
          state.pagination = action.payload?.pagination;

          // Check if subjects and grades are present in the response before mapping
          if (action.payload?.filters?.subjects) {
            const newSubjects = action.payload.filters.subjects.map((item) => {
              return { id: item.id, value: item.id, label: item.name };
            });

            state.subjects = [
              { id: -1, value: '', label: 'Select an option' },
              ...newSubjects,
            ];
          }

          if (action.payload?.filters?.grades) {
            const newGrades = action.payload.filters.grades.map((item) => {
              return { id: item.id, value: item.id, label: item.name };
            });

            state.grades = [
              { id: -1, value: '', label: 'Select an option' },
              ...newGrades,
            ];
          }
        })

        .addCase(getAllTagTypes.rejected, (state, action) => {
          console.log('rejected ', state);
          state.isLoading = false;
        });
  },
});

export const { } = TagTypesSlice.actions;
export default TagTypesSlice.reducer;
