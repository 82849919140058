// myReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {getListDropSubjects} from "../../services/subjects";

interface SubjectsState {
    subjectsList: object[];
    pagination: {};
    metaTags: object[];
    grades: object[];
    dropSubjects?: object[];
}

const initialState: SubjectsState = {
    subjectsList: [],
    pagination:{},
    metaTags: [],
    grades: [],
    dropSubjects: []
};

const mySlice = createSlice({
    name: 'subjects',
    initialState,
    reducers: {
        setSubjects(state, action: PayloadAction<any>) {
            console.log('action', action.payload);
            const { items, pagination, filters } = action.payload;
            console.log('############################################', filters)
            state.subjectsList = items;
            state.pagination = pagination;

            const newMetaTags = filters?.tag_type.map((item) => {
                return { id: item.id, value: item.id , label: item.name};
            });
            state.metaTags = [{ id: -1, value: "", label: "Select an option" }, ...newMetaTags];

            const newGrades = filters?.grades.map((item) => {
                return { id: item.id, value: item.id , label: item.name};
            });
            state.grades = [{ id: -1, value: "", label: "Select an option" }, ...newGrades];

        },
        setListDropSubjects(state, action){
            const { data } = action.payload;
            // const dropSubjects = [...data]
            const dropSubjects = data?.map((item) => {
                return { 
                    id: item.id, 
                    value: item.id, 
                    label: item.name, 
                    grades: Array.isArray(item?.grades) ? item?.grades.map(g => ({id: g?.id, value: g?.id, label: g?.name})) : []};
            });
            if (Array.isArray(dropSubjects)) {
                // You can safely spread dropSubjects here
                state.dropSubjects = [
                    // { id: -1, value: "", label: "Select an option", grades: []}, 
                    ...dropSubjects
                ];
            } else {
                // Handle the case where dropSubjects is not an array
                console.error("dropSubjects is not an array.");
            }        }
    },
});

export const { setSubjects, setListDropSubjects } = mySlice.actions;
export default mySlice.reducer;
