/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

interface OBJECT {
  id: number;
  label: string;
  value: string | number;
}

type CustomDropdownProps = {
  title?: string;
  value?: string | number | null;
  dataArray: OBJECT[];
  onChangeHandler: (e: any) => void;
  sxClass?: object;
  displayEmpty?: any;
  loading?: boolean;
  parentStyle?: string;
  handleClearClick?: () => void;
  showCancel?: boolean;
  disabled?: boolean;
  required?: boolean;
  labelParentClass?: string;
  IconParentClass?: string;
  padding?: boolean;
};

export default function Dropdown(props: CustomDropdownProps) {
  const {
    onChangeHandler,
    title,
    dataArray,
    value,
    displayEmpty,
    loading,
    parentStyle,
    required,
    labelParentClass = '',
    IconParentClass = '',
    sxClass,
  } = props;
  const handleChange = (event: any) => {
    onChangeHandler(event.target.value);
  };

  return (
    <div className={`relative ${parentStyle || ''} w-full`}>
      <Typography
        className={` ${
          props?.padding ? 'pb-2.5' : 'pb-2'
        } text-[15px] capitalize ${labelParentClass}`}
        variant="caption"
        display="block"
      >
        {title}

        {required && (
          <Typography
            className="text-main-red"
            variant="caption"
            display="inline-block"
          >
            &nbsp;*
          </Typography>
        )}
      </Typography>

      <select
        style={{
          appearance: 'none',
          ...sxClass,
        }}
        value={value}
        onChange={handleChange}
        className="!rounded-[5px] border border-[#ECECEC] px-3 py-[9px] text-sm min-w-full"
      >
        {!displayEmpty && dataArray?.length < 1 && (
          <option disabled={true} value="">
            <em>No Data</em>
          </option>
        )}

        {displayEmpty && (
          <option disabled={true} value="">
            <em>Select</em>
          </option>
        )}

        {dataArray?.map(
          (e: any): JSX.Element => (
            <option
              disabled={e.disable === true}
              key={e?.id}
              value={e?.value}
              // sx={{ fontSize: 'smaller' }}
            >
              {e?.label}
            </option>
          )
        )}

        {loading && (
          <MenuItem disabled={true}>
            <div className="text-center w-full">
              <CircularProgress color="success" size="20px" />
            </div>
          </MenuItem>
        )}
      </select>
      <div className="text-accent p-2 absolute right-0 bottom-0">
        <KeyboardArrowDownIcon
          // onClick={handleOpen}
          className={`cursor-pointer ${IconParentClass} m-auto !text-[20px]`}
        />
      </div>
    </div>
  );
}
