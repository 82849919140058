import { createSlice } from '@reduxjs/toolkit';

interface IForm {
  isOptionsValid: boolean;
  isAnswersValid: boolean;
}

const initialState: IForm = {
  isOptionsValid: false,
  isAnswersValid: false,
};

const subQuestionValidationSlice = createSlice({
  name: 'validation',
  initialState,
  reducers: {
    setIsOptionsValid: (state, action) => {
      state.isOptionsValid = action.payload;
    },
    setIsAnswersValid: (state, action) => {
      state.isAnswersValid = action.payload;
    },
  },
});

export const { setIsOptionsValid, setIsAnswersValid } =
  subQuestionValidationSlice.actions;

export default subQuestionValidationSlice.reducer;
