import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface AuthenticationState {
  isLogged: boolean;
  user: {
    user_name: string;
    role: null;
    user_id: number;
  };
  token: null;
}

const initialState: AuthenticationState = {
  isLogged: false,
  user: null,
  token: null,
};

const AuthenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    removeUser: () => initialState,
    setIsLogged: (state) => {
      state.isLogged = true;
    },
    updateUserInfo: (state, action) => {
      state.user = action.payload;
    },
    setIsLogout: (state) => {
      toast.dismiss();
        toast.success('Logged out successfully');
      state.isLogged = false;
      state.user = null;
      state.token = null;
    },
    setUser: (state, action: PayloadAction<any>) => {
      if (!!action.payload) {
        const { user, token } = action.payload;
        setIsLogged();
        state.user = user;
        state.token = token;
      }
    },
    updateUser: (state, action: PayloadAction<any>) => {
      if (!!action.payload) {
        const { user } = action.payload;
        setIsLogged();
        state = {
          ...state,
          user,
        };
      }
    },
  },
});

export const { setIsLogged, setIsLogout, setUser, removeUser,
  updateUser, updateUserInfo } =
  AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
