import styled, { css } from 'styled-components';

export const QuestionContent = styled.div<{ parentMode?: any }>`
  /* Add styles for the question content */
  ${(props) =>
    props.parentMode &&
    css`
      margin-top: 10px;
      padding: 30px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
    `}
`;

export const DeleteQuestion = styled.div`
width: 136px;
height: 40px;
padding: 0.7rem 1rem;
font-size: 1rem;
border-radius: 0.3rem;
background-color: white;
border: 1px solid rgb(250, 155, 49);
color: rgb(239, 100, 31);
cursor: pointer;
margin: 0px 5px;
outline: none;
text-align:center;
`;