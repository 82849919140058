import React, { useState } from "react";
import { QuestionContent } from "./styled";
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from "components/styled";
import ButtonLink from "components/ButtonLink";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useNavigate } from "react-router-dom";
import { setClosePreview } from "redux/slices/PreviewSlice";

export default function PreviewEasyImageMath({
  currentQuestion,
  showAnswer,
  setShowAnswers = null,
  parentMode = "assessment",
  editMode = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editorStyle = {
    height: "150px", // Set the desired height here
  };
  const [editorValue, setEditorValue] = useState("");
  const [toolbar, setTolbar] = useState(
    currentQuestion.correct_answer.formatting_options
  );
  const maxWords = currentQuestion.correct_answer.max_length; // Set your desired word limit here

  const handleEditorChange = (value) => {
    if (value && value.length > maxWords) {
      // Limit the number of words
      const words = value.split(/\s+/).slice(0, maxWords);
      setEditorValue(words.join(" "));
    } else {
      setEditorValue(value);
    }
  };

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  return (
    <QuestionContent parentMode={parentMode == "question"}>
      <FullWidthContainer>
        <LeftContent>
          <BlockTitle
            dangerouslySetInnerHTML={{
              __html: `Question: ${currentQuestion?.question}`,
            }}
          />
        </LeftContent>
        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={"Go to edit"}
                onClick={() => handleEditClick()}
              />
              <ButtonLink
                margin
                title={"Delete"}
                onClick={() => {
                  //setSubQuestionId(currentQuestion?.id);
                  //setIsModalOpen(true);
                }}
              />
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => {
                  // @ts-ignore
                  if (setShowAnswers) setShowAnswers(!showAnswer);
                }}
   
                style={{
                  width: "150px",
                  height: "40px",
                  fontWeight: 600,
                  color:"#FFF",
                  borderRadius: "8px",
                  backgroundColor: "#FA9B31",
                }}
              >
                {showAnswer ? "Hide Answer" : "Show Answer"}
              </button>
              <button
                type="button"
                onClick={() => {
                    // @ts-ignore
                    dispatch(setClosePreview());
                  }}
              style={{
                    width: "150px",
                    height: "40px",
                    fontWeight: 600,
                    color:"#FFF",
                    borderRadius: "8px",
                    backgroundColor: "#FA9B31",
                }}
              >
               Back to edit
              </button>
        
            </>
          )}
        </RightContent>
      </FullWidthContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #D9D9D9",
          padding: "24px",
          marginBottom: "10px",
          backgroundColor: "#f0f0f0",
        }}
      >
        <div className="w-full">
          <ReactQuill
            style={editorStyle} // Apply the custom style here
            // readOnly={true}
            value={editorValue}
            onChange={handleEditorChange}
            modules={{
              toolbar: toolbar,
            }}
          />
          <p>Words used: {editorValue.trim().split(/\s+/).length}</p>
          <p>
            Words remaining: {maxWords - editorValue.trim().split(/\s+/).length}
          </p>
        </div>
      </div>
    </QuestionContent>
  );
}
