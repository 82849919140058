import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

interface IShowQuestion {
  currentQuestion: {};
  isLoading: boolean;
}

const initialState: IShowQuestion = {
  currentQuestion: {},

  isLoading: true,
};

// Get Question ByID

export const getQuestion = createAsyncThunk(
  'showQuestion/getQuestion',

  async (id: string) => {
    try {
      const resp = await axios(
        `${process.env.REACT_APP_API_URL}/api/question/${id}`
      );

      return resp?.data?.item;
    } catch (err) {
      console.log(err);
    }
  }
);

const showQuestionSlice = createSlice({
  name: 'showQuestion',

  initialState,

  reducers: {},

  extraReducers(builder) {
    builder

      .addCase(getQuestion.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getQuestion.fulfilled, (state, action) => {
        state.isLoading = false;

        state.currentQuestion = action.payload;
        localStorage.setItem(
          'currentQuestion',
          JSON.stringify(state.currentQuestion)
        );
      })

      .addCase(getQuestion.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const {} = showQuestionSlice.actions;

export default showQuestionSlice.reducer;
