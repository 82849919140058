import React, { ReactNode } from 'react';

import styled from 'styled-components';
import { BsBellFill, BsFillQuestionCircleFill } from 'react-icons/bs';
import profilePic from '../assets/images/profile-pic.png';
import { useNavigate } from 'react-router-dom';
import Font from 'react-font';

interface HeaderProps {
  children?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <StyledHeader>
        <Font family="Inter" weight={700}>
          <StyledTitle>Obelearn</StyledTitle>
        </Font>

        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <BsBellFill className="cursor-pointer text-[#E93837]" />
          <BsFillQuestionCircleFill
            onClick={() => navigate('/user-profile')}
            className="cursor-pointer text-[#E93837]"
          />
          <StyledIcon
            src={profilePic}
            onClick={() => navigate('/user-profile')}
            alt="user profile"
            className="cursor-pointer"
          />
          {/* <IoIosArrowDown className="cursor-pointer" /> */}
        </div>
      </StyledHeader>
      {children}
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 35px 40px 35px;
  border-bottom: 1px solid #cccc;
  background-color: #fff;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #241f20;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #0000001f;
  font-family: Inter;
`;

const StyledTitle = styled.h2`
  font-size: 1.5rem;
  color: #000000;
`;

const StyledIcon = styled.img`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;
