import { createSlice } from '@reduxjs/toolkit';

interface IAccordion {
  openAccordion: boolean;
}

const initialState: IAccordion = {
  openAccordion: false,
};

const accordionSlice = createSlice({
  name: 'accordion',
  initialState,
  reducers: {
    toggleOpenAccordion: (state, action) => {
      state.openAccordion = !state.openAccordion;
    },
  },
});

export const { toggleOpenAccordion } = accordionSlice.actions;
export default accordionSlice.reducer;
