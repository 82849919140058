import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { BackgroundImage} from '../../AuthorQuestion/FillBlanks/SubChildren/ImageDragDrop/styled';
import ResizableDivContainer from '../../AuthorQuestion/FillBlanks/SubChildren/ImageDragDrop/ResizableDivContainer';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';


const PreviewImageWithText = ({
    currentQuestion,
    showAnswer,
    setShowAnswers = null,
    parentMode = 'assessment',
    editMode = false,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    console.log('[PreviewImageWithText.tsx] currentQuestion =', currentQuestion)

    const handleBackToEditClick = () => {
        dispatch(setClosePreview());
    };

    const handleGoToEditClick = () => {
        dispatch(setTypeQuestionOpenInEditMode({ type: currentQuestion?.type, id: currentQuestion?.id }));
        navigate(`/edit-subquestion/${currentQuestion.id}`);
    };

    return (
        <PreviewWrapper
            currentQuestion={currentQuestion}
            showAnswer={showAnswer}
            setShowAnswers={setShowAnswers}
            parentMode={parentMode}
            editMode={editMode}
            handleGoToEdit={handleGoToEditClick}
            handleBackToEdit={handleBackToEditClick}
        >
            <>
                <BackgroundImage
                    backgroundImageUrl={currentQuestion?.image?.source}
                    width={currentQuestion?.image?.width ? `${currentQuestion?.image?.width}px` : '100%'}
                >
                    {Array.isArray(currentQuestion?.correct_answer) &&  currentQuestion?.correct_answer.map((div, index) => (
                        <ResizableDivContainer
                            key={index}
                            div={div}
                            onDrag={(position) => {}}
                            onDrop={(e) => {}}
                        >
                            <div>{(showAnswer || editMode) ? div.content : ''}</div>
                        </ResizableDivContainer>
                    ))}
                </BackgroundImage>
            </>
        </PreviewWrapper>
    );
};

export default PreviewImageWithText;
