import axios, {AxiosError, AxiosResponse} from 'axios';

/** ------------------------------ Questions ------------------- */
export const addQuestionRequest = async (restQuestion: any) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/question`, restQuestion, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
      },
    })
    .then((body) => {
      data = body.data;
      status = body.status;
    });

  return { data, error, status };
};

export const editQuestionRequest = async (id: string, questionData: any) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .put(`${process.env.REACT_APP_API_URL}/api/question/${id}`, questionData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
      },
    })
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => {
      if (err.response) {
        status = err.response.status;
        error = err.response.data;
      } else if (err.request) {
        error = err.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
        error = err.message;
      }

      console.log('err.config', err.config);
    });

  return { data, error, status };
};

/** ------------------------------ SubQuestions ------------------- */
// export const addSubQuestionRequest = async (question: any, currentQuestion, subQuestion?: any) => {
export const addSubQuestionRequest = async (questionId: string, subQuestion?: any) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;
  // let questToBeAdded = subQuestion ? {...subQuestion} : {...question?.sub_questions.at(-1)}
  console.log('[question.ts addSubQuestionRequest()] questionId =', questionId, "subQuestion =", subQuestion)

  await axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/subQuestion`,
      {
        // ...question?.sub_questions.at(-1),
        ...subQuestion,
        question_id: questionId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: 'application/json',
        },
      }
    )
    .then((body) => {
      data = body.data;
      status = body.status;
    });

  return { data, error, status };
};

export const editSubQuestionRequest = async (
  id: string,
  sub_questionData: any
) => {
  let data: any = null;
  let error: any = null;
  let status: any = null;

  await axios
    .put(
      `${process.env.REACT_APP_API_URL}/api/subQuestion/${id}`,
      sub_questionData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: 'application/json',
        },
      }
    )
    .then((body) => {
      data = body.data;
      status = body.status;
    })
    .catch((err) => {
      if (err.response) {
        status = err.response.status;
        error = err.response.data;
      } else if (err.request) {
        error = err.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
        error = err.message;
      }

      console.log('err.config', err.config);
    });

  return { data, error, status };
};

export interface DeleteSubQuestionResponse {
  data?: any; // Adjust the type based on the expected response data
  error?: AxiosError | null;
  status?: number | null;
}

export const deleteSubQuestion = async (subQuestionId: string): Promise<DeleteSubQuestionResponse> => {
  try {
    const response: AxiosResponse = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/subQuestion/${subQuestionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/json',
          },
        }
    );

    return {
      data: response.data,
      status: response.status,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      status: (error.response && error.response.status) || null,
      error,
    };
  }
};

