import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';

import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import {
  BackgroundImage,
  DraggableOption,
} from '../../AuthorQuestion/FillBlanks/SubChildren/ImageDragDrop/styled';
import ResizableDivContainer from '../../AuthorQuestion/FillBlanks/SubChildren/ImageDragDrop/ResizableDivContainer';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';

const Option = ({ option, onDragStart }) => (
  <DraggableOption onDragStart={onDragStart} draggable>
    {option}
  </DraggableOption>
);

const PreviewImageDragDrop = ({
  currentQuestion,
  showAnswer,
  setShowAnswers = null,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [divs, setDivs] = useState<
    {
      x: number;
      y: number;
      width?: number;
      height?: number;
      content?: React.ReactNode;
    }[]
  >(currentQuestion?.correct_answer);

  const handleDragOptionStart = (e, option) => {
    e.dataTransfer.setData('text/plain', option);
  };

  const handleDropOption = (e, index) => {
    e.preventDefault();
    const draggedData = e.dataTransfer.getData('text/plain');
    console.log('cxcvxcvxc', draggedData);

    // If dragged data is an option, add it to the div
    if (currentQuestion?.options.includes(draggedData)) {
      console.log('1');
      if (!divs[index].content) {
        const updatedDivs = [...divs];
        updatedDivs[index] = { ...divs[index], content: draggedData };
        setDivs(updatedDivs);
        console.log('2');

      } else {
        console.log('3');
        // If dragged data is content, add it back to the options list
        if (divs[index].content) {
          const updatedDivs = [...divs];
          updatedDivs[index] = { ...divs[index], content: draggedData };
          setDivs(updatedDivs);
        }
      }
    }
  };

    console.log('"""""""""""""""""""""""""""""""""""""""""" test ', currentQuestion, divs);


  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
    dispatch(setTypeQuestionOpenInEditMode(null));
  };

  const handleGoToEditClick = () => {
    console.log('currentQuestion currentQuestion', currentQuestion)
    dispatch(setTypeQuestionOpenInEditMode({ type: currentQuestion?.type, id: currentQuestion?.id }));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer}
      setShowAnswers={setShowAnswers}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          //height: "150px",
          border: '1px solid #D9D9D9',
          padding: '24px',
          marginBottom: '10px',
          backgroundColor: '#f0f0f0',
        }}
      >
        <BackgroundImage
          backgroundImageUrl={currentQuestion?.image?.source}
          width={
            !!currentQuestion?.image?.width
              ? `${currentQuestion?.image?.width}px`
              : '100%'
          }
        >
            {Array.isArray(divs) && divs.map((div, index) => (
                <ResizableDivContainer
                    key={index}
                    div={div}
                    onDrag={(position) => console.log(position)}
                    onDrop={(e) => handleDropOption(e, index)}
                >
                    <div>{(showAnswer || editMode) ? div.content : ''}</div>
                </ResizableDivContainer>
            ))}
        </BackgroundImage>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {Array.isArray(currentQuestion?.options) ? (
          currentQuestion?.options?.map((option, index) => {
            if (typeof option == 'string') {
              return (
                <Option
                  key={index}
                  option={option}
                  onDragStart={(e) => handleDragOptionStart(e, option)}
                />
              );
            } else {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: 'auto',
                    height: '100px',
                    border: '1px solid #eee',
                    padding: '10px',
                    margin: '5px',
                    borderRadius: '6px',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Groupe name:</span>
                    {option?.name}
                  </div>
                  {option?.options?.map((option, index) => {
                    return (
                      <Option
                        key={index}
                        option={option}
                        onDragStart={(e) => handleDragOptionStart(e, option)}
                      />
                    );
                  })}
                </div>
              );
            }
          })
        ) : (
          <p>No options available</p>
        )}
      </div>
    </PreviewWrapper>
  );
};

export default PreviewImageDragDrop;
