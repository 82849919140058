import React, { useEffect, useRef } from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

interface Item {
    type: string;
    word: string;
}

interface FormatQuestionProps {
    id: string;
    onDrop?: (id: string, word: string) => void;
    filledWord?: string;
}

const FormatQuestion: React.FC<FormatQuestionProps> = ({ id, onDrop, filledWord = 'Response' }) => {
    const correct_answer = useSelector((state: RootState) => (state.question.subQuestions?.['cwdad'] as any)?.correct_answer);
    const [, drop] = useDrop({
        accept: 'FILLED_WORD',
        // Drop function
        drop: (item: Item, monitor: DropTargetMonitor) => {
            console.log('hello here 001', item);
            if (item && typeof item.word === 'string' && item.word.trim() !== '') {
                onDrop && onDrop(item.word, id);
            } else {
                // Handle the case where the word is empty or not a string
                console.warn('Dropped item has empty or invalid word.');
            }
        },
    });

    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // Calculate and set the width after the component is rendered
        if (divRef.current) {
            const width = divRef.current.getBoundingClientRect().width;
            // Use any logic here to set the width as needed
            divRef.current.style.width = `${width}px`;
        }
    }, [filledWord]); // Run the effect when filledWord changes

    return (
        <div
            ref={(node) => {
                drop(node);
                divRef.current = node;
            }}
            className="rectangle"
            style={{
                background: filledWord !== 'Response' ? '#F0F8ED' : '#fff',
            }}
        >
            {filledWord}
        </div>
    );
};

export default FormatQuestion;
