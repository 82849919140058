import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InsightState {

    documentListing: object[],
    documentPagination: object;
}

const initialState: InsightState = {

  documentListing: [],
  documentPagination: {},
};

const documentSlice = createSlice({
    name: 'document inventory',
    initialState,
    reducers: {
      setDocuemntsListing(state, action: PayloadAction<any>) {
            const { items, pagination } = action.payload;
            state.documentListing = items;
            state.documentPagination = pagination;
        },
    },
});

export const { setDocuemntsListing  } = documentSlice.actions;
export default documentSlice.reducer;
