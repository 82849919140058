import { Fragment, useEffect, useState } from 'react';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useNavigate } from 'react-router-dom';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';

const PreviewclozeDragDown = ({
    currentQuestion,
    showAnswer,
    setShowAnswers = null,
    parentMode = 'assessment',
    editMode = false,
    }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [filledWords, setFilledWords] = useState([]);
    const [_responses, set_responses] = useState([]);
    const [_correct, set_correct] = useState([]);

    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(
            currentQuestion?.template_response,
            'text/html'
        );
        const elements = Array.from(doc.body.children);

        let currentId = 0;


        const responses = elements.flatMap((element, key) => {
            if (element.tagName === 'P') {
                const parentContent = element.textContent.trim();
                const wordsArray = parentContent.split(/\s+|&nbsp;/);

                const result = wordsArray.map((word, index) => {
                    // Replace <br> with '*'
                    // console.log(word);
                    const cleanedWord = word === '<p><br></p>' ? '*' : word;

                    if (cleanedWord === 'Response') {
                        return { type: 'Response', content: cleanedWord, id: currentId, position:  index};
                    } else {
                        return { type: 'txt', content: cleanedWord, id: currentId, position:  index };
                    }
                });

                currentId++; // Increment id for the next <p> element
                return result;
            }
            return null;
        });

        const modifiedFilledWords = responses?.reduce((result, res) => {
            if (res.content === '') {
                // If content is an empty string, add a line break
                result.push({ type: 'txt', content: '\n', id: res.id });
            } else {
                // Otherwise, add the original content
                result.push(res);
            }
            return result;
        }, []);
        setFilledWords(modifiedFilledWords);
        set_responses(modifiedFilledWords);

    }, [showAnswer, currentQuestion]);

    const handleChangeCorrectAnswer = (value, id) => {
        let newArray = [..._correct];
        if (newArray.length !== 0) {
            let index = newArray.findIndex((item) => item?.id === id);
            if (index !== -1) {
                newArray[index] = { correct: value, id };
            } else {
                newArray.push({ correct: value, id });
            }
        } else {
            newArray.push({ correct: value, id });
        }

        set_correct(newArray);
    };

    const handleBackToEditClick = () => {
        dispatch(setClosePreview());
    };

    const handleGoToEditClick = () => {
        dispatch(
            setTypeQuestionOpenInEditMode({
                type: currentQuestion?.type,
                id: currentQuestion?.id,
            })
        );
        navigate(`/edit-subquestion/${currentQuestion.id}`);
    };

    const getCurrentAnswer = (id, position) => {
        const answerIndex = currentQuestion?.correct_answer?.findIndex((item) => item?.id == id && item?.position == position)
        return answerIndex > -1 ?  currentQuestion?.correct_answer[answerIndex].correct : ""
    }

    return (
        <PreviewWrapper
            currentQuestion={currentQuestion}
            showAnswer={showAnswer}
            setShowAnswers={setShowAnswers}
            parentMode={parentMode}
            editMode={editMode}
            handleGoToEdit={handleGoToEditClick}
            handleBackToEdit={handleBackToEditClick}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '150px',
                    border: '1px solid #D9D9D9',
                    padding: '24px',
                    marginBottom: '10px',
                    backgroundColor: '#f0f0f0',
                }}
            >
                <div>
                    {filledWords.map((value, index) => (
                        <Fragment key={index}>
                            {index > 0 && filledWords[index].id === filledWords[index - 1].id && (
                                <span>&nbsp;</span>
                            )}
                            {index > 0 && filledWords[index].id !== filledWords[index - 1].id && (
                                <br />
                            )}
                            {value.content === 'Response' ? (
                                <span className="align-center justify-center ml-1">
                                    {
                                        showAnswer ?
                                        <span className="underline font-bold">
                                            {getCurrentAnswer(value?.id, value?.position)}
                                        </span>
                                        :
                                        <select
                                        onChange={(e) =>
                                            handleChangeCorrectAnswer(e.target.value, value.id)
                                        }
                                        // value={
                                        //     showAnswer
                                        //         ? currentQuestion?.correct_answer.find((item) => item.id === value.id && item.position == value.position)?.correct || ''
                                        //         : ''
                                        // }
                                        style={{
                                            border: '1px solid #ddd',
                                            borderRadius: '6px',
                                            padding: '8px',
                                        }}
                                    >
                                        <option value={''}>Select option</option>

                                        { Array.isArray(currentQuestion?.options) &&
                                            currentQuestion.options.map((data, key) => {
                                                if (data?.lineId == value.id && data?.ResponseId == value.position) {
                                                    return (
                                                        <Fragment key={key}>
                                                            {data?.option &&
                                                                data?.option?.map((option, idx) => (
                                                                    <option
                                                                        key={idx}
                                                                        value={option.option}
                                                                        selected = {showAnswer && (getCurrentAnswer(value.id, value.position) == option.option)}
                                                                    >
                                                                        {option.option}
                                                                    </option>
                                                                ))}
                                                        </Fragment>
                                                    );
                                                }
                                            })
                                        }

                                    </select>
                                    }
                                </span>
                            ) : (
                                <span>{value.content} </span>
                            )}
                        </Fragment>
                    ))}
                </div>
            </div>
        </PreviewWrapper>
    );
};

export default PreviewclozeDragDown;
