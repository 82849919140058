import { createSlice } from '@reduxjs/toolkit';

//  type: 'mcs';
//   question: ''; // text
//   options: {
//     // json
//   };
//   correct_answer: ''; // text
//   attachment_type: ''; // image,audio,video,document
//   attachement: ''; // image,audio,video,document
//   // layout: {},
//   points: '1'; // integer
//   settings: {
//     multiple_response: false;
//   };

interface IUpdateSubQuestion {
  question: string;
  options: [];
  correct_answer: [];
  points: number;
  settings: [];
  subQuestions: object;
}

const initialState: IUpdateSubQuestion = {
  question: '',
  options: [],
  correct_answer: [],
  points: 0,
  settings: [],
  subQuestions: {},
};

const EditSubQuestionSlice = createSlice({
  name: 'editSubQuestion',
  initialState,
  reducers: {
    editVal: (state, action) => {
      const { key, val } = action.payload;
      state[key] = val;
    },
    setOldData: (state, action) => {
      const data = action.payload;
      state.question = data?.question;
      state.options = data?.options;
      state.correct_answer = data?.correct_answer;
      state.points = data?.points;
      state.settings = data?.settings;
    },
    EditSubQues: (state, action) => {
      state = action.payload;
      return state;
    },
    setSubQuestionEditVal: (state, action) => {
      const { key, val } = action.payload;
      console.log('###', key, val);
      // Check if the 'subQuestions' object exists in the state
      /*if (!state.subQuestions) {
        state.subQuestions = initialState.subQuestions;
      }*/
      // Check if the 'key' exists in 'subQuestions'
      if (key) {
        // If 'key' does not exist, create a new object with the 'key' and 'subKey'
        state.subQuestions = { [key]: val };
      }
    },
    editSubQuestionKeyValue: (state, action) => {
      const { key, value } = action.payload;
      if (key === 'cwdad' || key === 'cwdd' || key === 'cwt') {
        state.subQuestions[key] = value;
      }
    },
    setEditKeyValue: (state, action) => {
      const { key, subKey, value } = action.payload;
      console.log('setEditKeyValue', key, subKey, value);
      let index = null;

      // Check if the 'subQuestions' object exists in the state
      if (!state.subQuestions) {
        state.subQuestions = initialState.subQuestions;
      }
      // Check if the 'key' exists in 'subQuestions'
      if (!state.subQuestions[key]) {
        // If 'key' does not exist, create a new object with the 'key' and 'subKey'
        state.subQuestions[key] = { [subKey]: value };
      }
      if (
        key === 'chemistry' ||
        key === 'clozechemistry' ||
        key === 'ClozeMath' ||
        key === 'ClozeMathWImage'
      ) {
        state.subQuestions[key] = value;
      }
      // Add type "cwdad" when the key is "clozeDragDrop"
      if (key === 'cwdad') {
        state.subQuestions[key].type = 'cwdad';
        state.subQuestions[key][subKey] = value;
      }

      // Add type "cwdad" when the key is "clozeDropDown"
      if (key === 'cwdd') {
        state.subQuestions[key].type = 'cwdd';
        state.subQuestions[key][subKey] = value;
        if (subKey === 'options' || subKey === 'correct_answer') {
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            [subKey]: JSON.parse(value),
          };
        }
      }
      // Add type "cwdad" when the key is "clozeWithText"
      if (key === 'cwt') {
        state.subQuestions[key].type = 'cwt';
        state.subQuestions[key][subKey] = value;
      }
      if (key === 'draw') {
        state.subQuestions[key].type = 'draw';
        if (['source', 'width', 'height', 'alt'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {};
          }
          state.subQuestions[key].image = {
            ...state.subQuestions[key].image,
            [subKey]: value,
          };
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'liwdd') {
        console.log('...................', key, subKey, value);
        state.subQuestions[key].type = 'liwdd';

        if (['textHover', 'width', 'alt', 'source'].includes(subKey)) {
          console.log('0');
          if (!state.subQuestions[key].image) {
            console.log('100000000000000000');
            state.subQuestions[key].image = {
              [subKey]: value,
            };
          } else {
            console.log('200000000000000');
            state.subQuestions[key].image = {
              ...state.subQuestions[key].image,
              [subKey]: value,
            };
          }
        } else if (['duplicate', 'points'].includes(subKey)) {
          if (!state.subQuestions[key].settings) {
            state.subQuestions[key].settings = {
              [subKey]: value,
            };
          } else
            state.subQuestions[key].settings = {
              ...state.subQuestions[key].settings,
              [subKey]: value,
            };
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'liwt') {
        //label image with text
        state.subQuestions[key].type = 'liwt';
        if (['textHover', 'width', 'alt', 'source'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {
              [subKey]: value,
            };
          } else {
            state.subQuestions[key].image = {
              ...state.subQuestions[key].image,
              [subKey]: value,
            };
          }
        } else if (['duplicate', 'points'].includes(subKey)) {
          if (!state.subQuestions[key].settings) {
            state.subQuestions[key].settings = {
              [subKey]: value,
            };
          }
        }
        state.subQuestions[key][subKey] = value;
      }
      if (key === 'shad') {
        if (!state.subQuestions[key].type)
          state.subQuestions[key].type = 'shad';
        if (
          [
            'row_count',
            'column_count',
            'cell_width',
            'cell_height',
            'shaded',
          ].includes(subKey)
        ) {
          if (!state.subQuestions[key].options) {
            state.subQuestions[key].options = {};
          }
          state.subQuestions[key].options = {
            ...state.subQuestions[key].options,
            [subKey]: value,
          };
        } else if (['source', 'title', 'alt'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {};
          }
          state.subQuestions[key].image = {
            ...state.subQuestions[key].image,
            [subKey]: value,
          };
        } else if (['border'].includes(subKey)) {
          if (!state.subQuestions[key].canvas_container_style) {
            state.subQuestions[key].canvas_container_style = {};
          }
          state.subQuestions[key].canvas_container_style = {
            ...state.subQuestions[key].canvas_container_style,
            [subKey]: value,
          };
        } else if (['correct_answer'].includes(subKey)) {
          if (!state.subQuestions[key].correct_answer) {
            state.subQuestions[key].correct_answer = {};
          }
          state.subQuestions[key].correct_answer = {
            ...state.subQuestions[key].correct_answer,
            ...value,
          };
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'tokenHighlight') {
        state.subQuestions[key][subKey] = value;
      }
      if (key === 'taf') {
        state.subQuestions[key] = {
          ...state.subQuestions[key],
          [subKey]: value,
        };
      }
      if (
        [
          'mcs',
          'mcmr',
          'mcbl',
          'cms',
          'cmi',
          'cml',
          'machli',
          'class',
          'ordlis',
          'sortlis',
        ].includes(key)
      ) {
        state.subQuestions = { ...state.subQuestions, [key]: value };
      }
    },
    resetData: () => initialState,
  },
});

export const {
  editVal,
  setOldData,
  EditSubQues,
  setSubQuestionEditVal,
  resetData,
  setEditKeyValue,
  editSubQuestionKeyValue,
} = EditSubQuestionSlice.actions;

export default EditSubQuestionSlice.reducer;
