// myReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GradesState {
  gradesList: object[];
  pagination: {};
  metaTags: object[];
  subjects: object[];
  dropGrades: { id: number; value: string; label: string }[];
}

const initialState: GradesState = {
  gradesList: [],
  pagination: {},
  metaTags: [],
  subjects: [],
  dropGrades: [],
};

const mySlice = createSlice({
  name: 'grades',
  initialState,
  reducers: {
    setGrades(state, action: PayloadAction<any>) {
      const { items, pagination, filters } = action.payload;
      state.gradesList = items;
      state.pagination = pagination;

      state.metaTags = filters?.tag_type.map((item) => {
        return { id: item.id, value: item.id, label: item.name };
      });
      /*      state.metaTags = [
        { id: -1, value: '', label: 'Select an option' },
        ...newMetaTags,
      ];*/
      state.subjects = filters?.subjects?.map((item) => {
        return { id: item.id, value: item.id, label: item.name };
      });

      /*      state.subjects = [
              { id: -1, value: '', label: 'Select an option' },
              ...newSubjects,
            ];*/
    },
    setListDropGrades(state, action) {
      const { data } = action.payload;
      state.dropGrades = data?.map((item) => {
        return { id: item.id, value: item.id, label: item.name };
      });

      /*
            state.dropGrades = [{ id: -1, value: "", label: "Select an option" }, ...dropGrades];
      */
    },
  },
});

export const { setGrades, setListDropGrades } = mySlice.actions;
export default mySlice.reducer;
