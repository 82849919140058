import React, { useEffect, useState } from 'react';
import FormatQuestion from '../../AuthorQuestion/FillBlanks/SubChildren/ClozeDragDrop/FormatQuestion';
import Word from '../../AuthorQuestion/FillBlanks/SubChildren/ClozeDragDrop/Word';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';

const PreviewclozeDragDrop = ({
  currentQuestion,
  showAnswer,
  setShowAnswers = null,
  parentMode = 'assessment',
  editMode = false
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [filledWords, setFilledWords] = useState([].fill(null));
  const [_responses, set_responses] = useState([]);

  useEffect(() => {
    if (showAnswer) {
      const correctAnswerJsonString = currentQuestion?.correct_answer;

      if (correctAnswerJsonString) {
        try {
          const parsedCorrectAnswer = correctAnswerJsonString;
          setFilledWords(parsedCorrectAnswer);
          set_responses(parsedCorrectAnswer);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
    } else {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
          currentQuestion?.template_response,
          'text/html'
      );

      const elements = Array.from(doc.body.children);
      let currentId = 0;

      const responses = elements.flatMap((element, key) => {
        if (element.tagName === 'P') {
          const parentContent = element.textContent.trim();
          const wordsArray = parentContent.split(/\s+|&nbsp;/);

          const result = wordsArray.map((word, index) => {
            // Replace <br> with '*'
            console.log(word);
            const cleanedWord = word === '<p><br></p>' ? '*' : word;

            if (cleanedWord === 'Response') {
              return { type: 'Response', content: cleanedWord, id: currentId };
            } else {
              return { type: 'txt', content: cleanedWord, id: currentId };
            }
          });

          currentId++; // Increment id for the next <p> element
          return result;
        }
        return null;
      });

      console.log('#responses', responses);

      console.log('#responses', responses);


      console.log('#responses', responses);

      const modifiedFilledWords = responses?.reduce((result, res) => {
        if (res?.content === '') {
          // If content is an empty string, add a line break
          result.push({ type: 'txt', content: '\n', id: res.id });
        } else {
          // Otherwise, add the original content
          result.push(res);
        }
        return result;
      }, []);
      console.log('aa',modifiedFilledWords)
      setFilledWords(modifiedFilledWords);
      set_responses(modifiedFilledWords);
    }
  }, [showAnswer]);

  const handleDrop = (index, word) => {
    let updatedWords = [...filledWords];
    const droppedIndex = updatedWords.findIndex((item) => item.id === index && item.content == 'Response');

    // Ensure that the item at droppedIndex is defined
    if (droppedIndex !== -1 && updatedWords[droppedIndex]) {
      updatedWords[droppedIndex]['content'] = word;
      set_responses(updatedWords);
      setFilledWords(updatedWords);
    }
  };

  const handleGoToEditClick = () => {
    dispatch(
        setTypeQuestionOpenInEditMode({
          type: currentQuestion?.type,
          id: currentQuestion?.id,
        })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
    dispatch(setTypeQuestionOpenInEditMode(null));
  };

  console.log('####', filledWords);

  return (
      <PreviewWrapper
          currentQuestion={currentQuestion}
          showAnswer={showAnswer}
          setShowAnswers={setShowAnswers}
          parentMode={parentMode}
          editMode={editMode}
          handleGoToEdit={handleGoToEditClick}
          handleBackToEdit={handleBackToEditClick}
      >
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              //height: "150px",
              border: "1px solid #D9D9D9",
              padding: "24px",
              marginBottom: "10px",
              backgroundColor: "#f0f0f0",
            }}
        >
          <div>
            {filledWords.map((res, index) => (
                <React.Fragment key={index}>
                  {index > 0 && filledWords[index].id === filledWords[index - 1].id && (
                      <span>&nbsp;</span>
                  )}
                  {index > 0 && filledWords[index].id !== filledWords[index - 1].id && (
                      <br />
                  )}
                  <div
                      draggable
                      //onDragStart={(e) => handleDragStart(e, index)}
                      //onDragOver={handleDragOver}
                      style={{ display: 'inline-block' }}
                  >
                    {res.type === 'Response' ? (
                        <FormatQuestion
                            key={res.id}
                            id={res.id}
                            onDrop={(word, id) => handleDrop(id, word)}
                            filledWord={filledWords[index].content}
                        />
                    ) : (
                        <p key={index} style={{ display: 'inline' }}>{res.content}</p>
                    )}
                  </div>
                </React.Fragment>
            ))}
          </div>

        </div>
        <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
        >
          {Array.isArray(currentQuestion?.options) ? (
              currentQuestion?.options.map((word, index) => (
                  <Word key={index} word={word} />
              ))
          ) : (
              <p>No options available</p>
          )}
        </div>
      </PreviewWrapper>
  );
};

export default PreviewclozeDragDrop;
