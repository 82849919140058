import React, { useEffect, useRef, useState } from 'react';
import { QuestionContent, DeleteQuestion } from './styles';
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from '../../../components/styled';
import Button from '../../../components/Button';

import ButtonLink from '../../../components/ButtonLink';
import DOMPurify from 'dompurify';

import { useTranslation } from 'react-i18next';
import { deleteSubQuestionById } from '../../../redux/slices/EditQuestionSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
  deleteSubQuestion,
  DeleteSubQuestionResponse,
} from '../../../api-rest/questions';

import QuestiontypeModal from '../../QuestiontypeModal';

type PreviewWrapperProps = {
  currentQuestion: any;
  showAnswer: boolean;
  setShowAnswers: any;
  parentMode: string;
  editMode: boolean;
  children: React.ReactNode;
  handleBackToEdit?: () => void;
  handleGoToEdit?: (e: React.MouseEvent<HTMLButtonElement>) => void; // Modified type
  handleShowAnswer?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /*handleDelete?:(e:React.MouseEvent<HTMLButtonElement>)=>void;*/
};

const PreviewWrapper = ({
  currentQuestion,
  showAnswer,
  setShowAnswers,
  parentMode,
  editMode,
  children,
  handleBackToEdit,
  handleGoToEdit,
  handleShowAnswer,
 
}: PreviewWrapperProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const questionRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = async () => {
    console.log("Deleting");

    const { status }: DeleteSubQuestionResponse = await deleteSubQuestion(
      currentQuestion?.id
    );

    if (status === 200) {
      await dispatch(deleteSubQuestionById(currentQuestion?.id));
      toast.success('Sub question deleted successfully!');
      window.location.reload();
    } else return;
  };

  // parse an html string/*
  useEffect(() => {
    questionRef.current.innerHTML = DOMPurify.sanitize(
      currentQuestion?.question
    );
  }, [questionRef, currentQuestion?.question]);

  return (
    <QuestionContent parentMode={parentMode === 'question'}>
      <FullWidthContainer>
        <LeftContent>
          <BlockTitle>
            {t('question')}
            :&nbsp;
            <span ref={questionRef} />
          </BlockTitle>
        </LeftContent>

        <RightContent>
          {parentMode === 'question' && !editMode && (
            <>
              <Button
                margin
                title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                onClick={
                  handleShowAnswer
                    ? handleShowAnswer
                    : (e) => {
                      e.preventDefault()
                      setShowAnswers(!showAnswer)}
                }
              />

              <Button title={'Back to edit'} onClick={handleBackToEdit} />
            </>
          )}

          {parentMode === 'preview-assessment' && (
            <>
              <Button
                margin
                title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                onClick={
                  handleShowAnswer
                    ? handleShowAnswer
                    : (e) => {
                      e.preventDefault()
                      setShowAnswers(!showAnswer)}
                }
              />
            </>
          )}

          {editMode && (
            <>
              <ButtonLink
                margin
                title={t('btn.go_to_edit')}
                onClick={(e) => handleGoToEdit && handleGoToEdit(e)}
              />
              <DeleteQuestion onClick={() => {
              setIsModalOpen(true);}}> {t('btn.delete')}</DeleteQuestion>
              {/* <Button title={t('btn.delete')} onClick={handleDelete} /> */}
             
            </>
          )}
           {isModalOpen && (
          <QuestiontypeModal
            // @ts-ignore
            setIsModalOpen={setIsModalOpen}
            id={currentQuestion?.id}
            isModalOpen={isModalOpen}
          />
        )}

        </RightContent>
      </FullWidthContainer>

      {children}
    </QuestionContent>
  );
};

export default PreviewWrapper;

// Set default props
PreviewWrapper.defaultProps = {
  parentMode: 'assessment',
  editMode: false,
};
