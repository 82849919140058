import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DesignElement from '../partials/DesignElement/DesignElement';
import DropZone from '../partials/DropZone/DropZone';
import DraggableElement from '../partials/DraggableElement/DraggableElement';
import { TOptionItem } from 'types';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import Button from 'components/Button';

type MatchListBlockProps = {
  stimulus: TOptionItem[];
  possibRes: TOptionItem[];
  currentCorrectChoices: any[];
  handleDrop: (index: number, word: string) => void;
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  hasGroup?: boolean;
  score?: number;
  showAnswer?: boolean;
  correctAnswers?: any[];
  handleDataRest?: any;
};

export const MatchListBlock = ({
  stimulus,
  possibRes,
  currentCorrectChoices,
  handleDrop,
  handlePointsChange,
  editMode,
  hasGroup,
  score,
  showAnswer,
  correctAnswers,
  handleDataRest,
}: MatchListBlockProps) => {
  const { t } = useTranslation();

  const textRef = useRef<Array<HTMLDivElement | null>>([]);
  const correctTextRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    stimulus?.map((item, i) => {
      // parse html string
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
      return textRef.current[i].innerHTML;
    });
  }, [stimulus]);

  useEffect(() => {
    showAnswer &&
      correctAnswers?.map((item, i) => {
        // parse html string
        if (correctTextRef.current[i]) {
          correctTextRef.current[i].innerHTML = DOMPurify.sanitize(item);
        }
        return correctTextRef.current[i].innerHTML;
      });
  }, [correctAnswers, showAnswer]);

  return (
    <DndProvider backend={HTML5Backend}>
      {handleDataRest && <Button title={t('reset')} onClick={handleDataRest} />}
      <div className="mt-4">
        <div
          className={'w-full flex items-center justify-center'}
          style={{
            border: '1px solid #ccc',
            borderRadius: '6px',
            width: '100%',
          }}
        >
          <div className="max-w-[40%]">
            {stimulus?.map((item, i) => (
              <div key={item.id} className="flex items-center">
                <div className="p-2 border-solid border border-grey-500 my-4 rounded-md min-w-[300px] min-h-[48px] ">
                  <div ref={(el) => (textRef.current[i] = el)} />
                </div>
              </div>
            ))}
          </div>

          <div>
            {stimulus?.map((item, key) => (
              <div key={item.id} className="flex items-center">
                <div className="p-2 my-4 h-[48px]">
                  <DesignElement />
                </div>
              </div>
            ))}
          </div>

          <div className="max-w-[40%]">
            {stimulus?.map((item, key) => (
              <div key={item?.id} className="flex items-center gap-2">
                {currentCorrectChoices && (
                  <DropZone
                    id={key}
                    onDrop={handleDrop}
                    filledWord={currentCorrectChoices[key]}
                  />
                )}
                {showAnswer && correctAnswers && (
                  <div
                    className={`${
                      correctAnswers[key] === currentCorrectChoices[key]
                        ? 'border-2 p-1 border-green-500'
                        : 'border-2 p-1 border-red-500'
                    }`}
                  >
                    <div ref={(el) => (correctTextRef.current[key] = el)} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-between mt-4 gap-2">
          {/* Choices list */}
          {hasGroup ? (
            <div>has group</div>
          ) : (
            <div className="flex items-center gap-1 flex-wrap">
              {possibRes?.map((item, key) => (
                <DraggableElement key={key} label={item.label} />
              ))}
            </div>
          )}

          <div className="flex items-center justify-between mt-4 gap-2">
            {!editMode && (
              <div
                className="flex items-center gap-10 p-2"
                style={{
                  border: '1px solid #eee',
                  borderRadius: '6px',
                  width: '300px',
                }}
              >
                <span>{t('points')}</span>
                {score && correctAnswers ? (
                  <input
                    className="outline-none"
                    type="number"
                    value={score}
                    readOnly
                  />
                ) : (
                  <input
                    className="outline-none"
                    placeholder="100"
                    type="number"
                    onChange={handlePointsChange}
                    value={score}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

MatchListBlock.defaultProps = {
  editMode: false,
};
