import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InsightState {

    deliveredQuestionListing: object[],
    questionPagination: object;
    deliveredAssessmentListing: object[],
    assessmentsListingPagination: object,

    inputsFiltres: object,
}

const initialState: InsightState = {

    deliveredQuestionListing: [],
    questionPagination: {},
    deliveredAssessmentListing: [],
    assessmentsListingPagination: {},

    inputsFiltres: {},
};

const deliveredSlice = createSlice({
    name: 'insights',
    initialState,
    reducers: {
        setDeliveredQuestionListing(state, action: PayloadAction<any>) {
            const { items, pagination, inputsFiltres } = action.payload;
            state.deliveredQuestionListing = items;
            state.questionPagination = pagination;
            state.inputsFiltres = inputsFiltres;
        },
        setDeliveredAssessmentListing(state, action: PayloadAction<any>) {
            const { items, pagination, inputsFiltres } = action.payload;
            state.deliveredAssessmentListing = items;
            state.assessmentsListingPagination = pagination;
            state.inputsFiltres = inputsFiltres;
        },
    },
});

export const { setDeliveredQuestionListing, setDeliveredAssessmentListing  } = deliveredSlice.actions;
export default deliveredSlice.reducer;
