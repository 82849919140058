import AppRoutes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import store from './redux/store';
import { Authentication } from './utils/Authentication';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
// import 'jodit/build/jodit.min.css';
import 'react-color-palette/lib/css/styles.css';

const persistor = persistStore(store);

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Authentication>
            <AppRoutes />
            <ToastContainer />
          </Authentication>
        </BrowserRouter>
      </PersistGate>
    </DndProvider>
  );
}

export default App;
