import React, { useEffect, useState } from 'react';
// @ts-ignore
import logo from '../../assets/images/Layer_1.png';
// @ts-ignore
import logoIcon from '../../assets/images/logo-icon1.png';
import { BsFillStarFill } from 'react-icons/bs';
import { FaChevronRight } from 'react-icons/fa';

import { MdDashboard, MdOutlineScoreboard, MdSubject } from 'react-icons/md';
import { BiEdit } from 'react-icons/bi';
import { GiUpgrade } from 'react-icons/gi';
import { AiFillTag } from 'react-icons/ai';
import { GoSettings } from 'react-icons/go';
import { FaUserTag } from 'react-icons/fa';
import { ImStatsDots } from 'react-icons/im';
import { HiDocument } from 'react-icons/hi';
import { TbLogout } from 'react-icons/tb';

// @ts-ignore
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { logout } from '../../redux/actions/AuthActions';
import { connect, useDispatch } from 'react-redux';
import { setIsLogout } from '../../redux/slices/AuthenticationSlice';
import { resetData } from '../../redux/slices/QuestionSlice';
import { resetAssessmentData } from '../../redux/slices/assessmentSlice';
import { setClosePreview } from '../../redux/slices/PreviewSlice';
import { resetEditModeData } from '../../redux/slices/EditModeSlice';
import { Colors } from '../../utils/Colors';

interface Menu {
  title: string;
  icon: JSX.Element;
  path: string;
  subItems?: {
    title: string;
    path: string;
  }[];
}

const Sidebar = (props) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const [activeMenu, setActiveMenu] = useState([]);
  const navigate = useNavigate();

  const authorMenu: Menu[] = [
    {
      title: 'Dashboard',
      icon: <MdDashboard />,
      path: '/admin-dashboard',
    },
    {
      title: 'Question Bank',
      icon: <BiEdit />,
      path: '/questions-listing',
      // subItems: [{ title: "Question Bank", path: "/questions-listing" }],
    },
    {
      title: 'Assessment',
      icon: <BsFillStarFill />,
      path: '/assessment',
      subItems: [
        { title: 'All Assessment', path: '/assessment-listing' },
        { title: 'New Assessment', path: '/new-assessment' },
        // { title: 'Base Templates', path: '/base-templates' },
      ],
    },
    {
      title: 'Tags',
      icon: <AiFillTag />,
      path: '/tags',
      subItems: [
        { title: 'Tag Types', path: '/tags-types' },
        { title: 'Tag Hierarchy', path: '/tags-hierarchy' },
      ],
    },
    // {
    //   title: 'Console',
    //   icon: <GoSettings />,
    //   path: '/console',
    //   subItems: [
    //     { title: 'User Console', path: '/user-console' },
    //     { title: 'Editor Console', path: '/editor-console' },
    //   ],
    // },
    {
      title: 'User Console',
      icon: <GoSettings />,
      path: '/user-management',
    },
    {
      title: 'Logout',
      icon: <TbLogout />,
      path: '/logout',
    },
  ];

  const clientMenu: Menu[] = [
    {
      title: 'Dashboard',
      icon: <MdDashboard />,
      path: '/client-dashboard',
    },
    {
      title: 'User Management',
      icon: <FaUserTag />,
      path: '/user-management',
    },
    {
      title: 'Subjects',
      icon: <MdSubject />,
      path: '/subject-listing',
    },
    {
      title: 'Grades',
      icon: <GiUpgrade />,
      path: '/grade-listing',
    },
    {
      title: 'Reports',
      icon: <ImStatsDots />,
      path: '/reports',
    },
    {
      title: 'Scoring Module',
      icon: <MdOutlineScoreboard />,
      path: '/scoring-module',
    },
    {
      title: 'Document Inventory',
      icon: <HiDocument />,
      path: '/document-inventory',
    },
    {
      title: 'Editor Console',
      icon: <GoSettings />,
      path: '/editor-console',
    },
    {
      title: 'Logout',
      icon: <TbLogout />,
      path: '/logout',
    },
  ];

  // Logout
  const logout = async () => {
    try {
      const type = localStorage.getItem('type');
      navigate(`/${type}-login`, { replace: true });
    } catch (err) {
      console.log(err);
    }
  };

  const handleItemClick = (path: string, hasSubItems: boolean) => {
    setActiveItem(path);

    if (hasSubItems && open) {
      setSubMenuOpen(true);
      //setOpen((prevOpen) => !prevOpen);
    } else if (hasSubItems && !open) {
      setOpen((prevOpen) => !prevOpen);
      setSubMenuOpen(true);
    } else if (path === '/logout') {
      dispatch(setIsLogout());
      dispatch(resetData());
      dispatch(resetAssessmentData());
      dispatch(resetEditModeData());
      logout();
    } else {
      navigate(path);
    }
    // @ts-ignore
    dispatch(setClosePreview());
  };
  const type = localStorage.getItem('type');

  useEffect(() => {
    if (type === 'admin') setActiveMenu(authorMenu);
    else setActiveMenu(clientMenu);
  }, [type]);

  return (
    <div className="flex">
      <div
        style={
          !open
            ? { backgroundImage: 'linear-gradient(#FA9B31, #E32127)' }
            : { backgroundColor: '#FFF' }
        }
        className={`bg-white min-h-screen p-5 pt-8 ${
          open ? 'w-72' : 'w-24'
        } duration-300 relative shadow-2xl`}
      >
        <FaChevronRight
          // style={{ color: Colors.forestGreen }}
          className={`bg-white p-2  text-3xl rounded-full absolute -right-3 top-20 border  cursor-pointer shadow-2xl ${
            open && 'rotate-180'
          }`}
          onClick={() => setOpen(!open)}
        />
        {/* Logo */}
        <div
          style={
            !open
              ? {
                  backgroundColor: '#fff',
                  borderRadius: '5px',
                  transitionDuration: '0',
                }
              : { backgroundColor: '#FFF', transitionDuration: '0' }
          }
          className="inline-flex"
        >
          <img
            className="block float-left mr-2 w-52"
            src={!open ? logoIcon : logo}
            alt=""
          />
        </div>

        {/* Menu */}

        <SidebarContainer>
          <SidebarContent>
            {/* Render sidebar menu items */}
            <ul style={{ width: '100%' }}>
              {activeMenu.map((menu, index) => (
                <MenuItem
                  key={index}
                  active={menu.path === activeItem}
                  onClick={() => handleItemClick(menu.path, !!menu.subItems)}
                  lastItem={index == activeMenu.length - 1}
                >
                  <MenuItemContent
                    clicked={
                      window.location.toString().includes(menu.path) ||
                      menu?.subItems?.some((item) =>
                        window.location.toString().includes(item.path)
                      )
                    }
                  >
                    <span
                      className="text-2xl block float-left pr-5"
                      title={menu.title}
                      style={!open ? { color: '#FFF' } : { color: '#CCC' }}
                    >
                      {menu.icon}
                    </span>
                    <span
                      className={`text-base font-medium flex-1 duration-200 ${
                        !open && 'hidden'
                      }`}
                    >
                      {menu.title}
                    </span>
                  </MenuItemContent>

                  {/* Render subitems */}
                  {open &&
                  menu.subItems &&
                  subMenuOpen &&
                  menu.path === activeItem ? (
                    <SubMenuContent>
                      {menu.subItems.map((subItem, subIndex) => (
                        <SubMenuItem
                          key={subIndex}
                          active={subItem.path === activeItem}
                          onClick={() => handleItemClick(subItem.path, false)}
                        >
                          {subItem.title}
                        </SubMenuItem>
                      ))}
                    </SubMenuContent>
                  ) : null}
                </MenuItem>
              ))}
            </ul>
          </SidebarContent>
        </SidebarContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { logout })(Sidebar);

// export default Sidebar;

const SidebarContainer = styled.div`
  display: flex;
  padding-left: 5px;
`;

const SidebarContent = styled.div`
  /* ... your styles here ... */
  width: 100%;
`;

// @ts-ignore
const ToggleButton = styled(FaChevronRight)`
  /* ... your styles here ... */
`;

const MenuItem = styled.li<{ active?: boolean; lastItem?: boolean }>`
  /* ... your styles here ... */
  color: #d6f4d280; /* text-gray-500 */
  font-size: 0.875rem; /* text-sm */
  display: flex;
  align-items: ${({ active }) => (active ? 'none' : 'center')};
  gap: 0.75rem; /* gap-x-4 */
  cursor: pointer;
  padding: 0.5rem 0; /* p-2 */
  flex-direction: ${({ active }) => (active ? 'column' : 'row')};

  border-radius: 0.375rem; /* rounded-md */
  margin-top: 0.5rem; /* mt-2 */
  bottom: ${({ lastItem }) => (lastItem ? '0px' : '')};
  position: ${({ lastItem }) => (lastItem ? 'fixed' : 'relative')};
`;

const SubMenuContent = styled.ul`
  /* ... your styles here ... */
`;

const SubMenuItem = styled(MenuItem)`
  /* ... your styles here ... */
  color: #2c2c2c;
  padding: 0.5rem 3rem; /* p-2 */
  font-weight: 600;
`;

const MenuItemContent = styled.div<{ clicked: boolean }>`
  /* ... your styles here ... */
  display: flex;
  flex-direction: row;
  padding: 0.5rem; /* p-2 */
  width: 100%;
  border-radius: 0.375rem; /* rounded-md */
  // background-color: ${({ clicked }) =>
    clicked ? Colors.pumpkin : ''}; /* hover:bg-[#ffe4e6] */
  color: ${({ clicked }) => (clicked ? '#FFD066' : '#2C2C2C')};
  &:hover {
    color: #ffd066;
    // background-color: ${Colors.pumpkin2}; /* hover:bg-[#ffe4e6] */
  }
`;
