import {device} from "../../utils/device";
import styled from "styled-components";
import {Colors} from "../../utils/Colors";

export const Container = styled.form`
  width: 100%;
  display: flex;
  margin-top: -50px;
  flex-direction: column;
  background: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;

  @media ${device.mobileL} {
    padding: 15px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: -50px;
  flex-direction: column;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;

  @media ${device.mobileL} {
    padding: 15px;
  }
`;

export const Block = styled.div`
  flex: 1;
  display: flex;
  /* flex-direction: column;*/
  margin-top: 5px;
  padding: 0px 0px 20px 13px;
  width: 100%;
  @media ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    & > * {
      flex: 1 1 100%; /* Make items take full width on smaller screens */
      margin-right: 5px;
    }
  }
`;

export const AdminDetailsContainer = styled.div`
  width: 100%;
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 5px;
  margin: 10px 0;
  padding: 22px;

  @media ${device.tablet} {
    padding: 15px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  min-width: 11em;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin: 5px;
  background-color: ${(props) => (props.disabled ? '#ccc' : '#FA9B31')};
  border: ${(props) => (props.disabled ? 'none' : '1px solid')};
  color: #fff;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : 'white')};
    color: ${Colors.forestGreen};
    border: 1px solid;
  }
`;

export const CancelButton = styled.button`
  min-width: 150px;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  margin: 5px;
  background-color: #fff;
  border: ${(props) => (props.disabled ? 'none' : '1px solid ')};
  color: ${Colors.forestGreen};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : Colors.forestGreen)};
    color: #fff;
  }
`;

export const AdminTitle = styled.div`
  /* font-style: normal;*/
  font-weight: 500;
  font-size: 14px;
  color: #35363a;
  margin-bottom: 10px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  margin: 12px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DropContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 0 15px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    padding: 15px;
  }
`;
