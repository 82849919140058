import {AnyAction, combineReducers, Reducer} from 'redux';
import storage from 'redux-persist/lib/storage';
// @ts-ignore
import schoolClassesSlice from './slices/schoolClassesSlice';
import {RootState} from '../store';
import AuthenticationSlice from '../slices/AuthenticationSlice';
import UserManagmentSlice from '../slices/UserManagementSlice';
import TagTypesSlice from '../slices/TagTypesSlice';
import TagHierarchySlice from '../slices/TagHierarchySlice';
import PermissionsSlice from '../slices/PermissionsSlice';
import subjectsSlice from '../slices/SubjectsSlice';
import GradesSlice from '../slices/GradesSlice';
import QuestionSlice from '../slices/QuestionSlice';
import AssessmentSlice from '../slices/assessmentSlice';
import deliveredtSlice from '../slices/deliveredSlice';
import documentSlice from '../slices/DocumentSlice';
import SubQuestionValidation from '../slices/SubQuestionValidation';
import PreviewSlice from '../slices/PreviewSlice';
import AccordionSlice from '../slices/AccordionSlice';
import ShowQuestionSlice from '../slices/showQuestionSlice';
import EditQuestionSlice from '../slices/EditQuestionSlice';
import EditSubQuestionSlice from 'redux/slices/EditSubQuestionSlice';
import EditModeSlice from 'redux/slices/EditModeSlice';

const appReducer = combineReducers({
  authentication: AuthenticationSlice,
  UserManagment: UserManagmentSlice,
  TagTypes: TagTypesSlice,
  hierarchy: TagHierarchySlice,
  permissions: PermissionsSlice,
  subjects: subjectsSlice,
  grades: GradesSlice,
    question: QuestionSlice,
    validation: SubQuestionValidation,
    assessments: AssessmentSlice,
    delivered: deliveredtSlice,
    documentInventory: documentSlice,
    preview: PreviewSlice,
    accordion: AccordionSlice,
    showQuestion: ShowQuestionSlice,
    editQuestion: EditQuestionSlice,
    editSubQuestion: EditSubQuestionSlice,
    editMode: EditModeSlice,
});
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  // @ts-ignore
  return appReducer(state, action);
};
export { rootReducer };
