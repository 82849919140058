// @ts-ignore
import * as actionTypes from './Types';
import axios from 'axios';

// Login
export const login = (email: any, password: any) => async (dispatch: any) => {
  try {
    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`,
        {email, password},
        {withCredentials: true}
    );
    const token = response?.data?.access_token;
    const user = response?.data?.user;
    try {
      localStorage.setItem('token', response.data.access_token);
      dispatch({
        type: actionTypes.LOGIN_USER,
        payload: user,
        token,
      });
    } catch (err) {
      // console.log(err);
      throw new Error('Something went wrong ' + err);
    }
    return response;
  } catch (err) {
    console.log('******* ', err);
  }
};

// Logout
export const logout = () => async (dispatch: any) => {
  await localStorage.removeItem('token');
  dispatch({
    type: actionTypes.LOGOUT_USER,
  });
};

// Forget Password
export const forgetPassword = (email: any) => async (dispatch: any) => {
  try {
    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/password/login`,
        {email},
        {withCredentials: true}
    );
  } catch (err) {
    console.log(err);
  }
};
