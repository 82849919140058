import React, {Fragment, useEffect, useState} from 'react';

import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';

import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';

const PreviewclozeWithText = ({
  currentQuestion,
  showAnswer,
  setShowAnswers = null,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const doc = new DOMParser().parseFromString(
    currentQuestion?.template_response,
    'text/html'
  );

  const [responses, setResponses] = useState({});
  const [elements, setElements] = useState([]);
  // const [filledWords, setFilledWords] = useState([]); 

    const handleResponseChange = (fieldId, value) => {
    setResponses({ ...responses, [fieldId]: value });
  };

  useEffect(() => {
      if (showAnswer) {
          setElements(currentQuestion?.correct_answer);
      }
      else  {
          // console.log("hello fromelse ", showAnswer)
      const parser = new DOMParser();
      const doc = parser.parseFromString(currentQuestion?.template_response, 'text/html');
      const elements = Array.from(doc.body.children);
      let currentId = 0;

      const responses = elements.flatMap((element, key) => {
          if (element.tagName === 'P') {
              const parentContent = element.textContent.trim();
              const wordsArray = parentContent.split(/\s+|&nbsp;/);

              const result = wordsArray.map((word, index) => {
                  const cleanedWord = word === '<p><br></p>' ? '*' : word;

                  if (cleanedWord === 'Response') {
                      return {
                          type: 'Response',
                          content: cleanedWord,
                          id: currentId,
                          position: index,
                      };
                  } else {
                      return { type: 'txt', content: cleanedWord, id: currentId, position: index };
                  }
              });

              currentId++;
              return result;
          }
          return null;
      });
          // console.log("resssss",responses)
      const modifiedFilledWords = responses?.reduce((result, res) => {
          if (res.content === '') {
              result.push({ type: 'txt', content: '\n', id: res.id, position: res.position });
          } else {
              result.push(res);
          }
          return result;
      }, []);
      setElements(modifiedFilledWords);
    }
  }, [currentQuestion, showAnswer]);

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode({ type: currentQuestion?.type, id: currentQuestion?.id }));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer}
      setShowAnswers={setShowAnswers}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
      handleShowAnswer={() => {setShowAnswers(!showAnswer)}}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
            //height: "150px",
          border: '1px solid #D9D9D9',
          padding: '24px',
          marginBottom: '10px',
          backgroundColor: '#f0f0f0',
        }}
      >
          <div>
              {elements.map((res, index) => (
                  <Fragment key={index}>
                      {index > 0 && res.id === elements[index - 1].id && <span>&nbsp;</span>}
                      {index > 0 && res.id !== elements[index - 1].id && <br />}
                      {res.content === 'Response' ? (
                          <span key={index} className="align-center justify-center ml-1">
                            <input
                              type="text"
                              id={`response-${index}`}
                              // value={res?.value || "" }
                              value={ !showAnswer ? (responses[`response-${index}`] ? 
                                responses[`response-${index}`] : "") : 
                                (res?.value || "")
                              }
                              onChange={(e) => handleResponseChange(`response-${index}`, e.target.value)}
                              className="border border-gray-300 rounded px-3 py-2 mb-2"
                            />
                          </span>
                      ) : (
                          <span>{res.content} </span>
                      )}
                  </Fragment>
              ))}
          </div>
      </div>
    </PreviewWrapper>
  );
};

export default PreviewclozeWithText;
