/**
 * Format objects to work with react-select component
 * @param obj
 * @returns formatted obj { id, value, label }
 */
const formatObjects = (obj: any) => {
  if (obj && Object.keys(obj)?.length !== 0) {
    let arr = obj?.map((item: any) => {
      return { id: item.id, value: item.id, label: item.name };
    });

    return arr;
  }
};

/**
 * Format objects to work with Match list component
 * @param obj
 * @returns formatted obj { id, label }
 */
const formatDataWithLabel = (obj: any) => {
  console.log('object');
  if (obj && Object.keys(obj)?.length !== 0) {
    let arr = obj?.map((item: string, key: number) => {
      const stringID = `item-${key + 1}-${new Date().getTime()}`;

      return { id: stringID, label: item };
    });
    return arr;
  }
};
/**
 * Format objects to work with Match list component
 * @param obj
 * @returns formatted obj { id, label }
 */
const formatDataWithLabelForDnD = (obj: any) => {
  if (obj && Object.keys(obj)?.length !== 0) {
    let arr = obj?.map((item: any, key: number) => {
      let arr2 = item.map((el: any) => {
        const stringID = `item-${key + 1}-${new Date().getTime()}`;
        return { id: stringID, label: el };
      });
      return arr2;
    });
    return arr;
  }
};

export { formatObjects, formatDataWithLabel, formatDataWithLabelForDnD };
