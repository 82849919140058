import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';

export interface PermissionsState {
    configuration: null | any;
    accessType: any[];
    admin: null | any[];
    editor: any[];
    teacher: any[];
    subjects: any[];
    grades: any[];
}

const initialState: PermissionsState = {
    configuration: null,
    accessType: [],
    admin: null,
    editor: [],
    teacher: [],
    subjects: [],
    grades: []
};

const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setPermissions: (
            state,
            action: PayloadAction<{
                accessType: string[];
                admin: any[];
                editor: any[];
                teacher: any[];
                Roles?: any[];
                grades?: any[];
                subjects?: any[];
            }>
        ) => {
            console.log('######', action.payload);
            const { accessType, Roles, grades, subjects } = action.payload;
            // @ts-ignore
            const { Admin, Editor, Teacher } = Roles;

            state.accessType = accessType.map((str) => ({
                id: Math.random().toString(36).substr(2, 9),
                name: str,
            }));
            state.admin = Admin;
            state.editor = Editor;
            state.teacher = Teacher;
            // You can use Roles, grades, subjects as needed
            // state.Roles = Roles;
            const newGrades = grades.map((item) => {
                return { id: item.id, value: item.id, label: item.name };
            });
           // state.grades = [{ id: -1, value: "", label: "Select an option" }, { id: 0, value: "all", label: "All" }, ...newGrades];
           state.grades = [...newGrades];

            const newSubjects = subjects.map((item) => {
                return { id: item.id, value: item.id, label: item.name };
            });
           // state.subjects = [{ id: -1, value: "", label: "Select an option" }, { id: 0, value: "all", label: "All" }, ...newSubjects];
           state.subjects = [...newSubjects];
        },
    },
});

export const { setPermissions } = permissionsSlice.actions;

// Reducer
const permissionsReducer = permissionsSlice.reducer;
export default permissionsReducer;
