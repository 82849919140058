import { ChangeEventHandler, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import SortableElements from '../partials/SortableElements/SortableElements';
import { sortDraggedElement } from 'pages/AuthorQuestion/ClassifyMatch/utils';
import { ShowOrderListAnswers } from './ShowOrderListAnswers';

import { TOptionItem } from 'types';

type OrderListBlocProps = {
  orderList: TOptionItem[];
  correctAnswers?: TOptionItem[];
  handlePointsChange?: ChangeEventHandler<HTMLInputElement>;
  editMode?: boolean;
  setOrderList?: (value: any) => void;
  handleSortUpdate?: (res: TOptionItem[]) => void;
  showAnswer?: boolean;
  score?: number;
};

export const OrderListBloc = ({
  orderList,
  correctAnswers,
  handlePointsChange,
  editMode,
  setOrderList,
  handleSortUpdate,
  showAnswer,
  score,
}: OrderListBlocProps) => {
  const { t } = useTranslation();
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleSort = () => {
    const data = orderList;

    const res = sortDraggedElement({
      data,
      dragItem: dragItem,
      dragOverItem: dragOverItem,
    });

    setOrderList && setOrderList((prev) => (prev = res));
    handleSortUpdate && handleSortUpdate(res);
  };

  return (
    <div
      className="mt-4"
      style={{
        border: '1px solid #ccc',
        borderRadius: '6px',
        width: '100%',
      }}
    >
      <div className={'w-full p-2'}>
        {showAnswer ? (
          <ShowOrderListAnswers
            orderList={orderList}
            correctAnswers={correctAnswers}
            dragItem={dragItem}
            dragOverItem={dragOverItem}
            handleSort={handleSort}
          />
        ) : (
          <SortableElements
            columnTitles={orderList}
            handleColSort={handleSort}
            dragItem={dragItem}
            dragOverItem={dragOverItem}
          />
        )}
      </div>

      <div className="flex items-center justify-between mt-4 gap-2">
        {!editMode && (
          <div
            className="flex items-center gap-10 p-2"
            style={{
              border: '1px solid #eee',
              borderRadius: '6px',
              width: '300px',
            }}
          >
            <span>{t('points')}</span>
            {score && correctAnswers ? (
              <input
                className="outline-none"
                type="number"
                value={score}
                readOnly
              />
            ) : (
              <input
                className="outline-none"
                placeholder="100"
                type="number"
                value={score}
                onChange={handlePointsChange}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

OrderListBloc.defaultProps = {
  editMode: false,
};
