import styled from 'styled-components';
import { Colors } from '../../../../../utils/Colors';

export const DraggableOption = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: ${Colors.forestGreen2};
  color: black;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  cursor: move;
  user-drag: element;
  user-select: none;
`;

export const BackgroundImage = styled.div<{ width?: string; backgroundImageUrl: string }>`
  width: ${({ width }) => (width ? width : '100%')};
  max-width: 100%;
  height: 80vh;
  //padding-top: 100%; /* This is the aspect ratio hack for maintaining the original image's aspect ratio */
  background-image: url(${(props) => props.backgroundImageUrl});
  //background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-size: 100%;
`;
export const DivContainer = styled.div`
  display: flex;
  width: 180px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #ececec;
  background-color: ${Colors.forestGreen3};
  margin: 5px 0;
  /* display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  min-width: 200px;
  padding: 0 5px;
  height: 80px;
  resize: both;
  //cursor: move;
  overflow: auto;
  border-radius: 5px; */
`;

export const DeleteIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;

  svg {
    color: ${Colors.forestGreen};
    font-size: 20px;
  }
`;

export const ColorButton = styled.button`
  position: absolute;
  bottom: 5px;
  left: 5px;
  padding: 5px;
  cursor: pointer;
`;
