import { createSlice } from '@reduxjs/toolkit';

interface SubQuestion {
  id: string; // Assuming id is a string, adjust accordingly
  // Other properties of your sub-question
}
interface IUpdatedQuestion {
  name: string;
  description: string;
  grade_id: string;
  subject_id: string;
  score_id: string;
  difficult_id: string;
  notes: string;
  source: string;
  acknowledge: string;
  layout: {};
  tags: object[];
  status: number;
  access: number;
  sub_questions: SubQuestion[];
}

const initialState: IUpdatedQuestion = {
  name: '',
  description: '',
  grade_id: '',
  subject_id: '',
  score_id: '',
  difficult_id: '',
  notes: '',
  source: '',
  acknowledge: '',
  layout: null,
  tags: [],
  status: null,
  access: null,
  sub_questions: [],
};

const EditQuestionSlice = createSlice({
  name: 'editQuestion',
  initialState,
  reducers: {
    editVal: (state, action) => {
      const { key, val } = action.payload;
      console.log(key, val);
      state[key] = val;
    },
    setOldData: (state, action) => {
      return (state = action.payload);
    },
    deleteSubQuestionById: (state, action) => {
      const subQuestionIdToDelete = action.payload;

      // Find the index of the sub-question in the array
      const subQuestionIndex = state.sub_questions.findIndex(
          (subQuestion) => (subQuestion as SubQuestion).id === subQuestionIdToDelete
      );

      // If the sub-question is found, create a new state without the sub-question
      if (subQuestionIndex !== -1) {
        const newSubQuestions = [
          ...state.sub_questions.slice(0, subQuestionIndex),
          ...state.sub_questions.slice(subQuestionIndex + 1),
        ];

        // Update the state with the new sub-questions array
        return {
          ...state,
          sub_questions: newSubQuestions,
        };
      }

      // If the sub-question is not found, return the current state
      return state;
    },
    resetData: () => initialState,
  },
});

export const { editVal, setOldData, resetData, deleteSubQuestionById } = EditQuestionSlice.actions;

export default EditQuestionSlice.reducer;
