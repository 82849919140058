import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  title?: string;
  margin?: string | boolean;
  icon?: any;
  variant?: 'contained' | 'outlined';
  children?: ReactNode;
}

const StyledButton = styled.button<ButtonProps>`
  min-width: 136px;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.disabled
      ? '#ccc'
      : props.variant === 'contained'
      ? 'var(--accent)'
      : 'var(--light-accent)'};
  border: ${(props) => (props.disabled ? 'none' : '1px solid var(--accent)')};
  color: ${(props) =>
    props.disabled
      ? '#ccc'
      : props.variant === 'contained'
      ? '#fff'
      : 'var(--accent)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: ${(props) => (props.margin ? '0 5px' : '0')};
  outline: none;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : 'white')};
    color: ${(props) => (props.disabled ? 'grey' : 'var(--accent)')};
  }
`;

const Button: React.FC<ButtonProps> = ({
  disabled,
  margin,
  title,
  icon,
  variant,
  children,
  ...rest
}) => {
  return (
    <StyledButton
      margin={margin}
      disabled={disabled}
      className={`${icon && 'flex items-center gap-2 justify-center'}`}
      variant={variant}
      {...rest}
    >
      {icon} {title || children}
    </StyledButton>
  );
};

Button.defaultProps = {
  variant: 'outlined',
};

export default Button;
