import { MutableRefObject } from 'react';

export type TOptionItem = {
  id: string;
  label: string;
  order?: number;
};

export type TSortDraggedElement = {
  data: TOptionItem[];
  dragItem: MutableRefObject<any>;
  dragOverItem: MutableRefObject<any>;
};

export type SubQuestionData = {
  type: string;
  question: string;
  correct_answer: {
    stimulus_list: string[];
    valid_response: {
      score: number;
      value: string[];
    };
    alt_response?: {
      id?: string;
      score: number;
      value: string[];
    }[];
  };
};

export type MatchListSubQuestionData = {
  type: string;
  question: string;
  correct_answer: {
    stimulus_list: string[];
    valid_response: {
      score: number;
      value: string[];
    };
    alt_response?: {
      id?: string;
      score: number;
      value: string[];
    }[];
    group_possible_responses: boolean;
    possible_response_groups: {
      title: string;
      responses: string[];
    }[];
  };
};

export type ClassificationSubQuestionData = {
  type: string;
  question: string;
  correct_answer: {
    ui_style: {
      column_titles: string[];
      row_titles: string[];
    };
    possible_responses: string[];
    valid_response: {
      score: string;
      value: string[];
    };
    alt_response?: {
      score: string;
      value: string[];
    }[];
  };
};

export type OrderListSubQuestionData = {
  type: string;
  question: string;
  correct_answer: {
    stimulus_list: string[];
    valid_response: {
      score: number;
      value: string[];
    };
    alt_response?: {
      score: number;
      value: string[];
    }[];
  };
};

export const ItemTypes = {
  MATCH: 'match',
  CLASS: 'class',
};

export type GroupMatchList = {
  id: string;
  title: string;
  responses: string[];
};

export type AnswerTab = {
  id: number;
  content: string;
};
