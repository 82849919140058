import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Sidebar from './clientAdmin/Sidebar';

interface MainLayoutProps {
  header?: ReactNode;
  children?: ReactNode;
}

const MainLayoutWrapper = styled.div`
  display: flex;
  height: 100%;
  background-color: #f6f6f6;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const Content = styled.div`
  flex: 1;
  padding: 16px;
`;

const MainLayout: React.FC<MainLayoutProps> = ({ header, children }) => {
  return (
    <MainLayoutWrapper>
      <Sidebar />
      <ContentWrapper>
        {header}
        <Content>{children}</Content>
      </ContentWrapper>
    </MainLayoutWrapper>
  );
};

export default MainLayout;
