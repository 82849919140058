import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import MainLayout from '../../components/MainLayout';
import SubHeader from '../../components/SubHeader';
import { Block, Button, Buttons, CancelButton, Container, Line } from '../../components/styled';

import { useForm } from 'react-hook-form';

import { AiOutlineClose } from 'react-icons/ai';
import axios from 'axios';
import { Input, Label } from '../../components/InputWithLabel/styled';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/spinner/Spinner';

const CreateTagHierarchy = () => {
  const navigate = useNavigate();
  const [AllTags, setAllTags] = useState([]);
  // Tag ID
  const [tagId, setTagId] = useState([]);
  // Tag Name
  const [tagName, setTagName] = useState('');
  //@ts-ignore
  const [widgets, setWidgets] = useState([]);
  const [allTagTypes, setAllTagTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // @ts-ignore
    // dispatch(getAllTagTypes());
    setIsLoading(true);
    const getAllTagTypes = async () => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/api/tagHierarchy`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              Accept: 'application/json',
            },
          }
        );
        setAllTagTypes(response.data?.items);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getAllTagTypes();
  }, []);

  const handleOnDrag = (e: React.DragEvent, widjetType) => {
    e.dataTransfer.setData('widjetType', widjetType.name);
    setTagId([...tagId, widjetType.id]);
  };

  const handleOnDrop = (e: React.DragEvent) => {
    const widjetType = e.dataTransfer.getData('widjetType');
    //@ts-ignore
    setWidgets([...widgets, widjetType]);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handlerChange = (e) => {
    // setSelectedType(e.target.value);
    setAllTags(allTagTypes.find((tag) => tag.name === e.target.value)?.tags);
  };

  // Headers Config
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      Accept: 'application/json',
    },
  };
  // Add Tag Hierarchy
  const addTagHierarchy = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tagHierarchy/`,
        { name: tagName, tags: tagId },
        config
      );

      if (response.status === 200) {
        toast.success('Tag Hierarchy added successfully!');
        navigate('/tags-hierarchy');
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <MainLayout
      header={
        <Header
          children={
            <SubHeader
              title={'Dashboard'}
              subTitle={'Create New Tag Hierarchy'}
              showButtons={['tagsHierarchy']}
            />
          }
        />
      }
    >
      <Container>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Block style={{ width: '50%' }}>
              <select onChange={handlerChange}>
                <option value="" disabled selected>
                  Select an option
                </option>
                {allTagTypes.map((tag, index) => {
                  return (
                    <option key={index} value={tag?.name}>
                      {tag?.name}
                    </option>
                  );
                })}
              </select>
            </Block>
            <Line style={{ margin: '20px 5px' }} />
            <Block style={{ width: '50%' }}>
              <Label>Tag Name*</Label>
            </Block>
            <Block style={{ width: '50%' }}>
              <Input
                type="text"
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                required
              />
            </Block>

            {/* Drag & Drop */}
            <hr className="mt-10" />
            <div
              className="mt-10"
              style={{
                display: 'flex',
                gap: '20px',
                marginBottom: '50px',
              }}
            >
              <div
                className=""
                style={{
                  width: '50%',
                }}
              >
                <h2 className="mb-5">Selected Tags</h2>
                <div
                  className="flex flex-wrap gap-5"
                  onDrop={handleOnDrop}
                  onDragOver={handleDragOver}
                  style={{
                    width: '100%',
                    border: '1px solid #FFE6E6',
                    padding: '10px',
                    minHeight: '200px',
                  }}
                >
                  {/* @ts-ignore */}
                  {widgets.map((widget, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          border: '1px solid #ECECEC',
                          borderRadius: '5px',
                          padding: '10px 20px',
                          width: '110px',
                          height: '42px',
                          position: 'relative',
                        }}
                      >
                        {widget}
                        <AiOutlineClose
                          style={{
                            position: 'absolute',
                            right: '2px',
                            top: '2px',
                            cursor: 'pointer',
                            color: '#DE2128',
                          }}
                          onClick={() => {
                            // @ts-ignore
                            setWidgets(widgets.filter((tag) => tag !== widget));
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              {/*  */}
              <div
                style={{
                  width: '50%',
                }}
              >
                <h2 className="mb-5">Available Tags</h2>
                <div
                  className="widgets flex flex-wrap item-center flex-10"
                  style={{
                    width: '100%',
                    minHeight: '200px',
                    padding: '10px',
                    border: '1px solid #FFE6E6',
                  }}
                >
                  {AllTags.filter((tag) => !widgets.includes(tag?.name)).map(
                    (tag, index) => {
                      return (
                        <div
                          key={index}
                          className=""
                          draggable
                          onDragStart={(e) => handleOnDrag(e, tag)}
                          style={{
                            width: '210px',
                            height: '42px',
                            padding: '10px 20px',
                            border: '1px solid #ECECEC',
                            borderRadius: '5px',
                            marginRight: '20px',
                          }}
                        >
                          {tag?.name}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            {/* End Drag & Drop */}

            <div className="flex items-center gap-5 justify-end">
              <CancelButton type="button" title="Discard" style={{width: '149px'}}>
              Discard
          </CancelButton>
              <Button type="button" title="save" onClick={addTagHierarchy}>
            Save
          </Button>
              
            </div>
          </>
        )}
      </Container>
    </MainLayout>
  );
};

export default CreateTagHierarchy;
