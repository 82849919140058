import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssessmentState {
    questions: object[];
    pagination: object;
    assessment: { [key: string]: { [subKey: string]: any } };
    assessmentsListing: object[],
    assessmentsListingPagination: object,
    filters: any; // Define the type of your filters here
}

const initialState: AssessmentState = {
    questions: [],
    pagination: {},
    assessment: {},
    assessmentsListing: [],
    assessmentsListingPagination: {},
    filters: { subjects: [], grades: [], statuses: [] },
};

const assessmentSlice = createSlice({
    name: 'assessments',
    initialState,
    reducers: {
        setAssessments(state, action: PayloadAction<any>) {
            const { items, pagination } = action.payload;
            state.questions = items;
            state.pagination = pagination;
            state.filters = action.payload.filters; // Update the filters state
        },
        setOldData: (state, action) => {
            return (state = action.payload);
          },
        // setAssessmentsListing(state, action: PayloadAction<any>) {
        //     const { items, pagination } = action.payload;
        //     state.assessmentsListing = items;
        //     state.filters = action.payload.filters; 
        //     state.assessmentsListingPagination = pagination;
        // },
        setAssessmentsListing(state, action: PayloadAction<{ items: any[]; filters: any; pagination: any }>) {
            state.assessmentsListing = action.payload.items;
            state.filters = action.payload.filters; // Update the filters state
            state.assessmentsListingPagination = action.payload.pagination;
        },
        setKeyAssessmentValue: (state, action: PayloadAction<any>) => {
            const { key, subKey, value } = action.payload;

            // Initialize the key if it doesn't exist
            if (!state?.assessment[key]) {
                state.assessment[key] = {};
            }
            if(subKey) state.assessment[key][subKey] = value;
            else state.assessment[key] = value;
        },
        resetAssessmentData: (state) => state = { ...state, assessment: {} },
        setAssessmentEdit(state, action: PayloadAction<any>) {
            state.assessment = action.payload;

        },
    },
});

export const { setAssessments, setAssessmentsListing ,setOldData,setKeyAssessmentValue, resetAssessmentData, setAssessmentEdit } = assessmentSlice.actions;
export default assessmentSlice.reducer;
