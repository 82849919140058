import React, { Fragment, useEffect, useState } from 'react';
import { QuestionContent } from './styled';
import {
    BlockTitle,
    FullWidthContainer,
    LeftContent,
    RightContent,
} from '../../../components/styled';
import ButtonLink from '../../../components/ButtonLink';
import Button from '../../../components/Button';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";

export default function PreviewLabelmageMath({
    currentQuestion,
    showAnswer,
    setShowAnswers = null,
    parentMode = 'assessment',
    editMode = false
}) {
    //const dispatch = useDispatch();
    const [_correct, set_correct] = useState([]);
    const [elements, setElements] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let data = JSON.parse(
            JSON.stringify(currentQuestion.correct_answer?.response_containers)
        );
        for (let i = 0; i < data.length; i++) {
            let inputString = data[i].template;
            if (inputString) {
                const regex = /({\\colorbox{#e4e4e4}{{Response}}})|([^{}]+)/g;
                const parts = inputString.split(regex);
                let T = [];
                for (let i = 0; i < parts.length; i++) {
                    if (parts[i] !== undefined && parts[i] !== '') {
                        if (parts[i] === '{\\colorbox{#e4e4e4}{{Response}}}') {
                            T.push({ type: 'Res', content: 'Response' });
                        } else {
                            T.push({ type: 'txt', content: parts[i] });
                        }
                    }
                }
                data[i].template = T;
            }
        }


        setElements(data);
    }, []);

    const handleEditClick = () => {
        dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
        navigate(`/edit-subquestion/${currentQuestion.id}`);
    }

    return (
        <QuestionContent parentMode={parentMode == 'question'}>
            <FullWidthContainer>
                <LeftContent>
                    <BlockTitle
                        dangerouslySetInnerHTML={{
                            __html: `Question: ${currentQuestion?.question}`,
                        }}
                    />
                </LeftContent>
                <RightContent>
                    {editMode ? (
                        <>
                            <ButtonLink
                                margin
                                title={'Go to edit'}
                                onClick={() => handleEditClick()}
                            />
                            <Button
                                title={'Delete'}
                                onClick={() => {
                                    //setSubQuestionId(currentQuestion?.id);
                                    //setIsModalOpen(true);
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                margin
                                title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                                onClick={() => {
                                    // @ts-ignore
                                    if (setShowAnswers) setShowAnswers(!showAnswer);
                                }}
                            />
                            <Button
                                title={'Back to edit'}
                                onClick={() => {
                                    // @ts-ignore
                                    dispatch(setClosePreview());
                                }}
                            />
                        </>
                    )}
                </RightContent>
            </FullWidthContainer>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: `${currentQuestion.image.height}px`,
                    border: '1px solid #D9D9D9',
                    padding: '24px',
                    marginBottom: '10px',
                    backgroundColor: '#f0f0f0',
                }}
            >
                <div
                    style={{
                        // border: '1px solid #ddd',
                        position: 'relative',
                        width: `${currentQuestion.image.width}px`,
                        height: `${currentQuestion.image.height}px`,
                    }}
                >
                    {elements?.map((element, key) => (
                        <Fragment key={key}>
                            {element.template.map((template, key) => (
                                <div
                                    className="absolute w-full flex flex-row justify-between"
                                    style={{
                                        left: `${element.x}px`,
                                        top: `${element.y}px`,
                                        width: `${element.width} px`,
                                        height: `${element.height}px`,
                                    }}
                                    key={key}
                                >

                                    {
                                        template.type === "txt" ? (
                                            <div className='mt-10'>{template.content} {element.width}</div>
                                        ) : (
                                            <div className='border border-black' >
                                                {/* @ts-expect-error */}
                                                <math-field

                                                >
                                                    {/* @ts-expect-error */}
                                                </math-field>
                                            </div>

                                        )
                                    }
                                </div>
                            ))}
                        </Fragment>
                    ))}
                    <img
                        src={currentQuestion.image.src}
                        alt={currentQuestion.image.alt}
                        width={currentQuestion.image.width}
                        height={currentQuestion.image.height}
                    />
                </div>
            </div>
        </QuestionContent>
    );
}
