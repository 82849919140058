import React, { useEffect, useRef } from 'react';

import { useNavigate } from 'react-router';

import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';

import { Option, OptionsList } from './styled';
import DOMPurify from 'dompurify';

const PreviewMultipleStandard = ({
  currentQuestion,
  showAnswer,
  setShowAnswers = null,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const textRef = useRef<Array<HTMLDivElement | null>>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  /*
  const handleDeleteClick = () => {
    console.log("Deleting");

    const { status }: DeleteSubQuestionResponse = await deleteSubQuestion(
      currentQuestion?.id
    );

    if (status === 200) {
      await dispatch(deleteSubQuestionById(currentQuestion?.id));
      toast.success('Sub question deleted successfully!');
    //  window.location.reload();
    } else return;
  };
*/
  useEffect(() => {
    currentQuestion?.options?.map((item, i) => {
      // parse html string
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
      return textRef.current[i].innerHTML;
    });
  }, [currentQuestion?.options]);

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer}
      setShowAnswers={setShowAnswers}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
      
    >
      <OptionsList>
        {Array.isArray(currentQuestion?.options) &&
          currentQuestion?.options.map((option, index) => (
            <Option
              key={index}
              className={
                showAnswer
                  ? index === currentQuestion?.correctIndex
                    ? 'correct'
                    : 'incorrect'
                  : ''
              }
              showAnswer={showAnswer}
              isCorrect={
                Array.isArray(
                  currentQuestion?.correct_answer['valid_response']?.value
                ) &&
                currentQuestion?.correct_answer['valid_response']?.value.some(
                  (item) => item?.id === option?.id
                )
              }
            >
              <input
                type={
                  currentQuestion?.settings?.multiple_response
                    ? 'checkbox'
                    : 'radio'
                }
                checked={
                  showAnswer &&
                  Array.isArray(
                    currentQuestion?.correct_answer['valid_response']?.value
                  ) &&
                  currentQuestion?.correct_answer['valid_response']?.value.some(
                    (item) => item?.id === option?.id
                  )
                }
              />
              {/* {option.label} */}
              <div ref={(el) => (textRef.current[index] = el)} />
            </Option>
          ))}
      </OptionsList>
    </PreviewWrapper>
  );
};

export default PreviewMultipleStandard;
