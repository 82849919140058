import React from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

interface ModalProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string | string[]; // Optional
  isModalOpen: boolean;
  api?: string; // Optional
  method?: any; // Optional, adjust the type accordingly
  type?: string; // Optional
  multiple?: boolean;
}
// @ts-ignore
const Modal: React.FC<ModalProps> = ({
  setIsModalOpen,
  id,
  isModalOpen,
  api,
  method,
  type,
  multiple = false,
}) => {
  const dispatch = useDispatch();
  //   Get token from localStorage
  const token = localStorage.getItem('token');

  //  Headers config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  // Delete User
  const deleteHandler = async (id, api) => {
    let typeDeleted = type === 'Assessment' ? 'assessement' : 'questions';
    try {
      if (multiple) {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/${api}/multidelete`,
          { [typeDeleted]: id },
          {
            headers: {
              Accept: 'application/json',
            },
          }
        );
      } else {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/${api}/${id}`,
          config
        );
      }
      // @ts-ignore
      if (method) dispatch(method);
      setIsModalOpen(false);
    } catch (err) {
      console.log(err);
      setIsModalOpen(false);
    }
  };

  return (
    <Overlay isOpen={isModalOpen}>
      <div
        className="z-50 p-4 overflow-x-hidden "
        style={{
          position: 'absolute',
          left: '50%',
          top: '300px',
          transform: 'translate(-50%)',
          width: '400px',
        }}
      >
        <div className="relative w-full max-h-full shadow-xl">
          <div className="relative bg-white rounded-lg shadow ">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="popup-modal"
              onClick={() => setIsModalOpen(false)}
            >
              <AiOutlineClose className="text-xl" />
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <BsTrashFill className="mx-auto mb-4 text-[#dc3545] w-14 h-14" />
              <h3 className="mb-5 text-lg font-bold text-gray-500">
                Are you sure ?
              </h3>
              <p className="mb-5 text-lg font-normal text-gray-500">
                You want to delete this {type}.
              </p>
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="text-gray-500 bg-white font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                onClick={() => deleteHandler(id, api)}
              >
                Yes
              </button>
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="text-white bg-red-600 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 "
                onClick={() => setIsModalOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export default Modal;
