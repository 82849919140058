import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROLE } from "../utils/roles";
import {useSelector} from "react-redux";
import {useAppSelector} from "../hooks/useAppSelector";

interface ProtectedRouteProps {
    children: JSX.Element;
    roles?: Array<ROLE>;
}

const ProtectedRoute = ({ children, roles }: ProtectedRouteProps) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // @ts-ignore
    const { user, token } = useAppSelector((state) => state?.authentication);

    const arr = roles || [];

    const userHasRequiredRole = user && arr.includes(user?.roles[0]);

    const checkUserToken = () => {
        const type = localStorage.getItem("type");
        if (!token) {
            setIsLoggedIn(false);

            return navigate( `/${type}-login`, { replace: true });
        }
        setIsLoggedIn(true);
    };

    const checkUserRole = () => {

        if (token && !userHasRequiredRole) {
            return navigate("/access-denied"); // CHECK THIS
        }
    };

    useEffect(() => {
        checkUserToken();
        checkUserRole();
    }, [isLoggedIn]);

    return isLoggedIn ? children : null;
};
export default ProtectedRoute;
