import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface TAgTypes {
  isLoading: boolean;
  allTagHierarchy: any[];
  pagination: {};
}

const initialState: TAgTypes = {
  allTagHierarchy: [],
  isLoading: true,
  pagination: null,
};

// Get all tag hierarchy
export const getAllTagHierarchy = createAsyncThunk(
  'author/getAllTagHierarchy',
  async (page) => {
    try {
      const resp = await axios(
        `${process.env.REACT_APP_API_URL}/api/tagHierarchy/datatable?page=${page[0]}&per_page=${page[1]}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/json',
          },
        }
      );
      console.log('***** => ', resp);

      return resp.data;
    } catch (error) {
      console.log('Err ', error);

      // return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

const TagHierarchySlice = createSlice({
  name: 'TAgTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTagHierarchy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTagHierarchy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allTagHierarchy = action.payload.items;
        state.pagination = action.payload.pagination;
      })
      .addCase(getAllTagHierarchy.rejected, (state, action) => {
        console.log('rejected ', state);
        state.isLoading = false;
      });
  },
});

export const {} = TagHierarchySlice.actions;
export default TagHierarchySlice.reducer;
