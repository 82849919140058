import React, { useEffect, useRef } from 'react';

import { useNavigate } from 'react-router';

import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';

import { Option, OptionsList } from './styled';
import DOMPurify from 'dompurify';

const PreviewTrueFalse = ({
  currentQuestion,
  showAnswer,
  setShowAnswers = null,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const textRef = useRef<Array<HTMLDivElement | null>>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = (e) => {
    e.preventDefault();
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  useEffect(() => {
    currentQuestion?.options?.map((item, i) => {
      // parse html string
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
      return textRef.current[i].innerHTML;
    });
  }, [currentQuestion?.options]);

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer}
      setShowAnswers={setShowAnswers}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={(e) => handleGoToEditClick(e)}
      handleBackToEdit={handleBackToEditClick}
    >
      <OptionsList>
        {currentQuestion?.options?.map((option, index) => (
          <Option
            key={index}
            showAnswer={showAnswer}
            isCorrect={
              Array.isArray(
                currentQuestion?.correct_answer['valid_response']?.value
              ) &&
              currentQuestion?.correct_answer['valid_response']?.value.some(
                (item) => item?.id === option?.id
              )
            }
          >
            <input
              type="radio"
              name="trueFalseOption"
              value={option.label}
              checked={
                showAnswer &&
                Array.isArray(
                  currentQuestion?.correct_answer['valid_response']?.value
                ) &&
                currentQuestion?.correct_answer['valid_response']?.value.some(
                  (item) => item?.id === option?.id
                )
              }
            />
            {/* {option.label} */}
            <div ref={(el) => (textRef.current[index] = el)} />
          </Option>
        ))}
      </OptionsList>
    </PreviewWrapper>
  );
};

export default PreviewTrueFalse;
