import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

interface ButtonProps {
    onClick?: (e) => void;
    disabled?: boolean;
    title?: string;
    margin?: string | boolean;
    icon?: any;
    to?: string;
}

const StyledButton = styled.button<ButtonProps>`
  width: 136px;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  background-color: ${(props) => (props.disabled ? '#ccc' : 'white')};
  border: ${(props) => (props.disabled ? 'none' : '1px solid #fa9b31')};
  color: #ef641f;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: ${(props) => (props.margin ? '0 5px' : '0')};
  outline: none;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : 'white')};
  }
`;

const ButtonLink: React.FC<ButtonProps> = ({
    onClick,
    disabled,
    margin,
    title,
    icon,
    to,
    }) => {
    if (to) {
        return (
            <Link to={to}>
                <StyledButton
                    margin={margin}
                    onClick={onClick}
                    disabled={disabled}
                    className={`${icon && 'flex items-center gap-2 justify-center'}`}
                >
                    {icon} {title}
                </StyledButton>
            </Link>
        );
    }

    return (
        <StyledButton
            margin={margin}
            onClick={onClick}
            disabled={disabled}
            className={`${icon && 'flex items-center gap-2 justify-center'}`}
        >
            {icon} {title}
        </StyledButton>
    );
};

export default ButtonLink;
