import React from 'react';
import {Link} from 'react-router-dom';
/**
 * import Global colors
 */
import {Colors} from '../utils/Colors';

export const NotFound: React.FC = (): JSX.Element => {
    return (
        <section className="flex items-center h-full p-16">
            <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                <div className="max-w-md text-center">
                    <h2 className="mb-8 font-extrabold text-9xl " style={{color: Colors.pumpkin}}>
                        <span className="sr-only">Error</span>404
                    </h2>
                    <p className="text-2xl font-semibold md:text-3xl">
                        Sorry, we couldn't find this page.
                    </p>
                    <p className="mt-4 mb-8">
                        But dont worry, you can find plenty of other things on our home
                        page.
                    </p>
                    <Link style={{backgroundColor: Colors.pumpkin}}
                          to="/client-dashboard"
                          className={"px-8 py-3 font-semibold rounded  text-white"}
                    >
                        Back to Dashboard
                    </Link>
                </div>
            </div>
        </section>
    );
};
