import DragContainer from 'components/DragContainer/DragContainer';
import DOMPurify from 'dompurify';
import { useEffect, useRef } from 'react';
import { TOptionItem } from 'types';

type ShowOrderListAnswersProps = {
  orderList: TOptionItem[];
  correctAnswers: TOptionItem[];
  dragItem: any;
  dragOverItem: any;
  handleSort: () => void;
};

export const ShowOrderListAnswers = ({
  orderList,
  correctAnswers,
  dragItem,
  dragOverItem,
  handleSort,
}: ShowOrderListAnswersProps) => {
  const textRef = useRef<Array<HTMLDivElement | null>>([]);
  const correctTextRef = useRef<Array<HTMLSpanElement | null>>([]);

  useEffect(() => {
    orderList?.map((item, i) => {
      // parse html string
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
      return textRef.current[i].innerHTML;
    });
  }, [orderList]);

  useEffect(() => {
    correctAnswers?.map((item, i) => {
      // parse html string
      if (correctTextRef.current[i]) {
        correctTextRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
      return correctTextRef.current[i].innerHTML;
    });
  }, [correctAnswers]);

  return orderList?.map((item, i) => {
    return (
      <>
        <DragContainer
          index={i}
          key={i}
          onDragEnd={handleSort}
          dragItem={dragItem}
          dragOverItem={dragOverItem}
        >
          <div ref={(el) => (textRef.current[i] = el)} />
        </DragContainer>

        <div
          className={`${
            correctAnswers[i]?.label === item?.label
              ? 'border-2 p-1 border-green-500'
              : 'border-2 p-1 border-red-500'
          }`}
        >
          <span ref={(el) => (correctTextRef.current[i] = el)} />
        </div>
      </>
    );
  });
};
