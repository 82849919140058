import React, { forwardRef } from 'react';
import { Container, ErrorMessage, Input, Label, Note } from './styled';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: string;
  type?: string;
  error?: any;
  placeholder?: string;
  note?: string;
  disabled?: boolean;
  height?: number | string;
  width?: number | string;
  message?: any;
}

const InputWithLabel = forwardRef(
  (
    {
      label,
      value,
      type = 'text',
      placeholder,
      disabled = false,
      note,
      error,
      width,
      height,
      message,
      ...props
    }: Props,
    ref
  ) => {
    return (
      <div>
        <Label>{label}</Label>
        <Input
          ref={ref}
          type={type}
          {...props}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          width={width}
          height={height}
        />
        {error && (
          <ErrorMessage>{message || `${label} is required. `}</ErrorMessage>
        )}
        {note && <Note>Note: {note}</Note>}
      </div>
    );
  }
);

export default InputWithLabel;
