import axios from 'axios';

type Authentication = {
  id: number;
  lastRequestAt?: number;
  token: string;
  rememberMe: boolean;
};

const MAX_BETWEEN_TWO_REQUEST = 60 * 60;

const generateLastRequestAt = (): number => {
  return Math.floor(Date.now() / 1000);
};

// @ts-ignore
const checkIfAlwaysAuthenticated = (): boolean => {
  // Get the current date
  const currentDate = new Date();

  const targetDate = new Date('2023-06-19 08:48:14');

  return true;
};

const getAuthentication = (): string => {
  const token = localStorage.getItem('token');
  //const token = JSON.parse(JSON.parse(storedToken));
  if (token) return token;
  else return null;
};

const setAuthentication = (token: string): void => {
  //auth.lastRequestAt = generateLastRequestAt();
  //localStorage.setItem("token", JSON.stringify(token));
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

const removeAuthentication = (): void => {
  localStorage.removeItem('token');
};

const reloadAuthentication = (): boolean => {
  if (!hasAuthentication()) return false;

  const token = getAuthentication();
  setAuthentication(token);

  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  return true;
};

const hasAuthentication = (): boolean => {
  const token = getAuthentication();

  if (token) return checkIfAlwaysAuthenticated();
};

export {
  setAuthentication,
  removeAuthentication,
  hasAuthentication,
  reloadAuthentication,
  getAuthentication,
};
