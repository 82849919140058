import DragContainer from 'components/DragContainer/DragContainer';

import DOMPurify from 'dompurify';
import { useEffect, useRef } from 'react';

type SortableElementsProps = {
  columnTitles: any;
  handleColSort: any;
  dragItem: any;
  dragOverItem: any;
};

const SortableElements = ({
  columnTitles,
  handleColSort,
  dragItem,
  dragOverItem,
}: SortableElementsProps): JSX.Element => {
  const textRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    columnTitles?.map((item, i) => {
      // parse html string
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(
          item?.label ? item?.label : item
        );
      }
      return textRef.current[i].innerHTML;
    });
  }, [columnTitles]);

  return columnTitles?.map((item, i) => {
    return (
      <DragContainer
        index={i}
        key={i}
        onDragEnd={handleColSort}
        dragItem={dragItem}
        dragOverItem={dragOverItem}
      >
        <div ref={(el) => (textRef.current[i] = el)} />
      </DragContainer>
    );
  });
};

export default SortableElements;
