import styled from 'styled-components';
import { device } from '../../utils/device';
/**
 * import Global colors
 */
import { Colors } from '../../utils/Colors';

export const StyledDashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.25rem 1.25rem 0;
  color: #241f20;
  @media ${device.tablet} {
    //flex-direction: column;
  }
`;

export const Titles = styled.h2`
  display: flex;
  flex-direction: column;
`;

export const StyledHeading = styled.h2`
  font-size: 14px;
  cursor: pointer;
  display: flex;
  color: #000000;
  @media ${device.tablet} {
    display: none;
  }
`;

export const StyledSubTitle = styled.h2`
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;

  align-items: center;
  gap: 10px;
  @media ${device.tablet} {
    display: flex;
    margin: 7px -30px;
    gap: 5px;
    justify-content: center;
  }
`;

export const StyledButton = styled.button<any>`
  background-color: #fffae8;
  padding: 0.5rem;
  min-width: 155px;
  height: 45px;
  border-radius: 10px;
  // border: ${(props) => (!props.border ? 'none' : '1px solid #DE2128')};
  border: 1px solid #fa9b31;
  margin-right: ${(props) => (props.spacing ? '20px' : '0px')};
  color: ${(props) => (props?.disabled ? Colors.gray : '#FA9B31')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  @media ${device.tablet} {
    width: 90%;
    height: auto;
  }
  &:hover {
    background-color: ${(props) => (props?.disabled ? '#FFFAE8' : '#fff')};
    /* border: 1px solid; */
    color: ${(props) => (props?.disabled ? Colors.gray : Colors.pumpkin)};
  }
`;
