import DOMPurify from 'dompurify';
import { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from 'types';

const DraggableElement = ({ label }) => {
  const elRef = useRef(null);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.MATCH,
      item: { text: label, droppedItems: elRef },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [label]
  );

  // parse an html string
  useEffect(() => {
    elRef.current.innerHTML = DOMPurify.sanitize(label);
  }, [elRef, label]);

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      <div
        className="p-2 border-solid border border-[#4a9d2d] rounded-md bg-[#4a9d2d] text-white"
        ref={elRef}
      />
    </div>
  );
};

export default DraggableElement;
