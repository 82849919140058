import React, { Fragment, useEffect, useState } from 'react';
import { QuestionContent } from './styled';
import {
    BlockTitle,
    FullWidthContainer,
    LeftContent,
    RightContent,
} from '../../../components/styled';
import ButtonLink from '../../../components/ButtonLink';
import Button from '../../../components/Button';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useNavigate } from 'react-router-dom';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

const mathlive = require('mathlive');

const PreviewMath = ({
    currentQuestion,
    showAnswer,
    setShowAnswers = null,
    parentMode = 'assessment',
    editMode = false,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //const dispatch = useDispatch();
    const [_correct, set_correct] = useState([]);
    const [elements, setElements] = useState([]);

    useEffect(() => {
        let inputString = currentQuestion.template_response;
        if (inputString) {
            const regex = /({\\colorbox{#e4e4e4}{{Response}}})|([^{}]+)/g;
            const parts = inputString.split(regex);
            let T = [];
            for (let i = 0; i < parts.length; i++) {
                if (parts[i] !== undefined && parts[i] !== '') {
                    if (parts[i] === '{\\colorbox{#e4e4e4}{{Response}}}') {
                        T.push({ type: 'Res', content: 'Response' });
                    } else {
                        T.push({ type: 'txt', content: parts[i] });
                    }
                }
            }

            setElements(T);
        }
    }, []);

    const handleEditClick = () => {
        console.log("1110", currentQuestion?.type);

        dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
        console.log("2220");

        navigate(`/edit-subquestion/${currentQuestion.id}`);
    }

    return (
        <QuestionContent parentMode={parentMode == 'question'}>
            <FullWidthContainer>
                <LeftContent>
                    <BlockTitle
                        dangerouslySetInnerHTML={{
                            __html: `Question: ${currentQuestion?.question}`,
                        }}
                    />
                </LeftContent>
                <RightContent>
                    {editMode ? (
                        <>
                            <ButtonLink margin title={'Go to edit'} onClick={() => handleEditClick()} />
                            <Button title={'Delete'} onClick={() => {
                                //setSubQuestionId(currentQuestion?.id);
                                //setIsModalOpen(true);
                            }} />
                        </>
                    ) : (
                        <>
                            <Button
                                margin
                                title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                                onClick={() => {
                                    // @ts-ignore
                                    if (setShowAnswers) setShowAnswers(!showAnswer);
                                }}
                            />
                            <Button
                                title={'Back to edit'}
                                onClick={() => {
                                    // @ts-ignore
                                    dispatch(setClosePreview());
                                }}
                            />
                        </>
                    )}
                </RightContent>
            </FullWidthContainer>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '150px',
                    border: '1px solid #D9D9D9',
                    padding: '24px',
                    marginBottom: '10px',
                    backgroundColor: '#f0f0f0',
                }}
            >
                {elements.map((elm, key) => (
                    <Fragment key={key}>
                        {elm.type === 'txt' ? (
                            <p >{elm.content}</p>
                        ) : (
                            <Fragment>
                                {/* @ts-expect-error */}
                                <math-field
                                // onInput={(evt) => {
                                //   setValue(evt.target.value);
                                //   setData({
                                //     ...data,
                                //     settings: {
                                //       ...data.settings,
                                //       formulaTemplate: evt.target.value,
                                //     },
                                //   });
                                // }}
                                >
                                    {/* @ts-expect-error */}
                                </math-field>
                            </Fragment>
                        )}
                    </Fragment>
                ))}
            </div>
        </QuestionContent>
    );
};

export default PreviewMath;
