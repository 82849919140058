import { TOptionItem } from 'types';
import { DnDBetweenLists } from '../partials/DnDBetweenLists/DnDBetweenLists';
import { useTranslation } from 'react-i18next';

type SortListBlocProps = {
  orderList: TOptionItem[];
  correctAnswers?: TOptionItem[];
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  setOrderList?: (value: any) => void;
  handleSortUpdate?: (res: TOptionItem[]) => void;
  showAnswer?: boolean;
  score?: number;
};

export const SortListBloc = ({
  orderList,
  correctAnswers,
  handlePointsChange,
  editMode,
  showAnswer,
  score,
}: SortListBlocProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'w-full p-2'}>
        <DnDBetweenLists
          correctAnswers={correctAnswers}
          orderList={orderList}
          showAnswer={showAnswer}
        />
      </div>

      <div className="flex items-center justify-between mt-4 gap-2">
        {!editMode && (
          <div
            className="flex items-center gap-10 p-2"
            style={{
              border: '1px solid #eee',
              borderRadius: '6px',
              width: '300px',
            }}
          >
            <span>{t('points')}</span>
            {score && correctAnswers ? (
              <input
                className="outline-none"
                type="number"
                value={score}
                readOnly
              />
            ) : (
              <input
                className="outline-none"
                placeholder="100"
                type="number"
                value={score}
                onChange={handlePointsChange}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

SortListBloc.defaultProps = {
  editMode: false,
};
