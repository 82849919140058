import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { TOptionItem } from 'types';
import { move, reorder } from 'utils/dragAndDrop';

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#d7ffc9' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? '#f7ccb2' : 'lightgrey',
  padding: grid,
  width: '50%',
});

type DnDBetweenListsProps = {
  orderList?: TOptionItem[];
  correctAnswers?: TOptionItem[];
  showAnswer?: boolean;
  withDelete?: boolean;
};

export function DnDBetweenLists({
  orderList,
  correctAnswers,
  showAnswer,
  withDelete,
}: DnDBetweenListsProps) {
  const { t } = useTranslation();

  const [state, setState] = useState([orderList, []]);
  const correctTextRef = useRef<Array<HTMLSpanElement | null>>([]);

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];

      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState);
    }
  }

  useEffect(() => {
    showAnswer &&
      correctAnswers?.map((item, i) => {
        // parse html string
        if (correctTextRef.current[i] && correctTextRef.current[i].innerHTML) {
          correctTextRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
        }
        return correctTextRef.current[i]?.innerHTML;
      });
  }, [correctAnswers, showAnswer]);
  

  return (
    <div style={{ display: 'flex' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        {state.map((el, ind) => (
          <Droppable key={ind} droppableId={`${ind}`}>
            {(provided, snapshot) => (
              <div
                key={ind}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                <h4 key={ind} className="mb-2 text-center font-bold">
                  {ind === 0 ? 'Source' : 'Target'}
                </h4>
                {el.map((item, index) => (
                  <div key={index}>
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item.label,
                            }}
                          >
                            {withDelete && (
                              <button
                                type="button"
                                onClick={() => {
                                  const newState = [...state];
                                  newState[ind].splice(index, 1);
                                  setState(
                                    newState.filter((group) => group.length)
                                  );
                                }}
                              >
                                {t('btn.delete')}
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                    {ind === 1 && showAnswer && (
                      <div
                        className={`${
                          correctAnswers[index]?.label === item?.label
                            ? 'border-2 p-1 border-green-500'
                            : 'border-2 p-1 border-red-500'
                        }`}
                      >
                        <span
                          ref={(el) => (correctTextRef.current[index] = el)}
                        />
                      </div>
                    )}
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </div>
  );
}

