import {createSlice} from '@reduxjs/toolkit';

interface IPreview {
  displayPreview: boolean;
}

const initialState: IPreview = {
  displayPreview: false,
};

const previewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    setDisplayPreview: (state) => {
      state.displayPreview = true;
    },
    setClosePreview: (state) => {
      state.displayPreview = false;
    },
  },
});

export const { setDisplayPreview, setClosePreview } = previewSlice.actions;
export default previewSlice.reducer;
